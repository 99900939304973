import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-content: center;
`;

export const HelpButton = styled.button`
  width: 38px;
  height: 38px;
  padding: 3px;
  margin: 0 35% 10px 35%;
  border-radius: 50%;
  /* position: fixed; */
  z-index: 6;
  font-size: 20px;
  /* right: 28px; */
  bottom: 28px;
  color: white;
  background-color: rgb(25, 118, 210);
  cursor: pointer;
  border: 0;

  &:hover {
    background-color: #445a99;
  }
`;

// start of styles file.

import { TextField } from '@mui/material';
import styled, { keyframes } from 'styled-components';

export const ModalGuestBackground = styled.div`
  z-index: 8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const ModalGuestContent = styled.div`
  margin: auto;
  width: 80%;
`;

export const Container = styled.div`
  /* max-width: 400px; */
  @media (max-width: 1440px) {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
  }
  width: 80%;
  padding: 20px;
  margin: 0 10%;

  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  position: relative;
`;

export const Input = styled.input`
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const StyledMuiInput = styled(TextField)({
  width: '100%',
  // marginBottom: '20px',
  // padding: '10px',
  // borderRadius: '4px',
  // border: '1px solid #ccc'
});

export const StyledHelperText = styled.span`
  color: '#d32f2f';
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
`;

export const StyledAddButton = styled.button`
  width: 56px;
  height: 56px;
  padding: 2px;
  border-radius: 50%;
  position: fixed;
  z-index: 6;
  font-size: 30px;
  right: 28px;
  bottom: 28px;
  color: white;
  background-color: rgb(25, 118, 210);
  cursor: pointer;
  border: 0;

  &:hover {
    background-color: #445a99;
  }
`;

export const StyledSaveButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4c66af;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 100px;

  &:hover {
    background-color: #445a99;
  }
`;

export const StatusButton = styled.button`
  padding: 5px;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  display: block; /* Para garantir que o label fique acima do campo */
  font-weight: bold; /* Para destacar o label */
  color: #555; /* Cor do texto do label */
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const NewTaskContainer = styled.div<{ $visible: boolean }>`
  @media (max-width: 1440px) {
    width: 50vw;
  }
  @media (max-width: 800px) {
    width: 90vw;
  }
  width: 30vw;

  right: 0;
  top: 0;

  z-index: 7;
  display: ${props => (props.$visible ? 'block' : 'none')};
  animation: ${props => (props.$visible ? fadeIn : fadeOut)} 0.8s forwards;
  position: fixed;
  height: 100vh;
  background-color: white;
  padding: 0px 20px;
  margin: 0px;
  overflow-y: auto;

  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
`;

export const Overlay = styled.div<{ $visible: boolean }>`
  left: 0;
  top: 0;

  display: ${props => (props.$visible ? 'block' : 'none')};
  position: fixed;
  height: 100vw;
  width: 100vw;
  background-color: #3535355c;
  z-index: 5;
`;

// interface RowProps {
//   status: string;
// }

// export const StyledRow = styled.div<RowProps>`
//   cursor: pointer;
//   border-radius: 5px;
//   background-color: ${({ status }) => {
//     if (status === 'wait') {
//       return '#f8d7da9e';
//     }
//     if (status === 'in progress') {
//       return 'lightyellow';
//     }
//     return '#8ae78a7a';
//   }};
//   overflow: hidden;

//   &:hover {
//     background-color: #f5f5f5;
//   }
// `;

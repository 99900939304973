import styled, { css, keyframes } from 'styled-components';

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  transition: all 0.5s ease;
`;

export const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); }
  100% { transform: scale(1); }
`;

export const Select = styled.select<{
  isHighlighted?: boolean;
}>`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  pointer-events: 'auto';
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      animation: ${pulse} 1s ease infinite;
    `}
`;

import React, { useCallback, useState } from 'react';
import { useAuth } from '../../context/authContext';
import { Main, Select } from './styles';

const OverlayUsergroupRequired: React.FC = () => {
  const authContext = useAuth();
  const { user } = authContext;
  const [selectedGroup, setSelectedGroup] = useState<string>('');

  const handleGroupChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
      const { value } = e.target;

      setSelectedGroup(value);

      authContext.updateSelectedUsergroup(
        user.usergroups.find(
          (usergroup: any) => usergroup.userGroupId === parseInt(value, 10),
        ),
      );
    },
    [authContext, user.usergroups],
  );

  return (
    <>
      <Main>
        <Select value={selectedGroup} onChange={handleGroupChange}>
          <option value="" disabled>
            Bitte wähle hier das gewünschte Projekt aus.
          </option>
          {user.usergroups.map((usergroup: any) => (
            <option key={usergroup.userGroupId} value={usergroup.userGroupId}>
              {usergroup.name}
            </option>
          ))}
        </Select>
      </Main>
    </>
  );
};

export default OverlayUsergroupRequired;

import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Grid, Link, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../context/authContext';
import api from '../../services/api';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [messageFirstPwd, setMessageFirstPwd] = useState<string>('');
  const [messageWeakPwd, setMessageWeakPwd] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [helperTextPassword, setHelperTextPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = (): void => {
    setShowPassword(prev => !prev);
  };

  const checkPassword = useCallback(async (email: string) => {
    try {
      const response = await api.post('/users/checkPassword', {
        email,
        isReset: false,
      });
      return response.data;
    } catch (error: any) {
      console.error(error.response.data.message);
      return {};
    }
  }, []);

  const validateEmail = useCallback((email: string): boolean => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return false; // is not valid
    }
    return true; // is valid
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const user: SignInFormData = {
        email: data?.get('email')?.toString().toLowerCase() ?? '',
        password: data?.get('password')?.toString() ?? '',
      };

      const isValidEmail = validateEmail(user.email);

      if (isValidEmail) {
        setErrorEmail(false);
        if (showPwd) {
          const logged = await signIn(user);
          if (!logged.weakPassword) {
            if (logged.signIn) {
              navigate(logged.isGuest ? '/tasks' : '/');
            } else {
              setErrorPassword(true);
              setHelperTextPassword('Falsche E-Mail/Passwort-Kombination');
            }
          } else {
            setMessageWeakPwd(
              'Wir führen eine Sicherheitsverstärkung bei Insights durch und bitten unsere Kunden, ihre Passwörter zurückzusetzen. Sie haben eine E-Mail mit einem Link erhalten, über den Sie Ihr Passwort zurücksetzen können. Wir danken Ihnen für Ihr Verständnis.',
            );
          }
        } else {
          const hasPassword = await checkPassword(user.email);
          if (hasPassword.status) {
            setShowPwd(true);
          } else {
            setMessageFirstPwd(hasPassword.message);
          }
        }
      } else {
        setErrorEmail(true);
      }
    },
    [checkPassword, navigate, showPwd, signIn, validateEmail],
  );

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar>
          <Typography component="h1" variant="h5">
            Anmelden
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ width: '100%', mt: 2 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-Mail Adresse"
              name="email"
              error={errorEmail}
              helperText={errorEmail ? 'Invalid email format' : ''}
              autoComplete="email"
              autoFocus
            />

            {showPwd && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passwort"
                error={errorPassword}
                helperText={helperTextPassword}
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={handleSubmit}
            >
              {showPwd ? 'Anmelden' : 'Weiter'}
            </Button>
          </Box>
          {messageFirstPwd !== '' && (
            <h4 style={{ color: '#4D50FF' }}>{messageFirstPwd}</h4>
          )}
          {messageWeakPwd !== '' && (
            <h4 style={{ color: '#ffc100', fontSize: 'large' }}>
              {messageWeakPwd}
            </h4>
          )}
        </Box>
        {messageWeakPwd === '' && (
          <Grid container>
            <Grid item xs>
              <Link href="/forgotPassword" variant="body2">
                Passwort vergessen?
              </Link>
            </Grid>
          </Grid>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;

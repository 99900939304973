import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridEB } from './styles';

interface props {
  rows: any[];
  columns: any[];
  pageSizeOptions?: number;
  checkboxSelection?: boolean;
}

const GridMui: React.FC<props> = ({
  rows,
  columns,
  pageSizeOptions,
  checkboxSelection,
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGridEB
        style={{ backgroundColor: 'white' }}
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSizeOptions ?? 5,
            },
          },
        }}
        pageSizeOptions={[pageSizeOptions ?? 5]}
        checkboxSelection={checkboxSelection ?? false}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default GridMui;

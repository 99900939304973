import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../../services/api';
import Modal from '../../components/modal';
import { Content, PwdRequirements } from './styles';

interface SignInFormData {
  password: string;
  passwordConfirm: string;
}

interface ParamTypes {
  [key: string]: string;
  token: string;
}

const ResetPassword: React.FC = () => {
  const { token } = useParams<ParamTypes>();

  const [pwd, setPwd] = useState<string>('');

  const [showPassword, setShowPassword] = useState(false);

  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [successPassword, setSuccessPassword] = useState<any>('primary');
  const [focusedPassword, setFocusedPassword] = useState<any>(false);
  const [helperTextPassword, setHelperTextPassword] = useState<string>('');

  const [successConfirmPassword, setSuccessConfirmPassword] =
    useState<any>('primary');
  const [focusedConfirmPassword, setFocusedConfirmPassword] =
    useState<any>(false);
  const [errorConfirmPassword, setErrorConfirmPassword] =
    useState<boolean>(false);
  const [helperTextConfirmPassword, setHelperTextConfirmPassword] =
    useState<string>('');

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>('');
  const [alertSeverity, setAlertSeverity] = useState<any>('');

  const [mailUser, setMailUser] = useState<string>();
  const navigate = useNavigate();

  const handleTogglePassword = (): void => {
    setShowPassword(prev => !prev);
  };

  useEffect(() => {
    api
      .post('/users/checkToken', { token })
      .then(response => {
        if (response.status === 200) {
          setMailUser(response.data);
        }
      })
      .catch(error => {
        console.error(error.response.data.message);
      });
  });

  const handleSnackBar = useCallback(
    (open: boolean, message: string, severity: any) => {
      setSnackBarOpen(open);
      setSnackBarMessage(message);
      setAlertSeverity(severity);
    },
    [],
  );

  const checkSecurityPassword = useCallback((event: any) => {
    const password = event.target.value;
    // Regex explanation:
    // (?=.*[A-Z]) → At least one uppercase letter
    // (?=.*\d) → At least one number
    // (?=.*\W) →  At least one special character (\W includes any character that is not a letter or a number)
    // [A-Za-z\d\W]{12,} → Allowed character set with a minimum of 12 characters
    if (
      password !== '' &&
      /^(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{12,}$/i.test(password)
    ) {
      setErrorPassword(false);
      setSuccessPassword('success');
      setPwd(password);
      setFocusedPassword(true);
      setHelperTextPassword('gültiges Passwort');
    } else if (password === '') {
      setErrorPassword(false);
      setHelperTextPassword('');
      setSuccessPassword('primary');
    } else {
      setErrorPassword(true);
      setHelperTextPassword('Ungültige Passwortanforderungen');
    }
  }, []);

  const checkMatchedPassword = useCallback(
    (event: any) => {
      const confirmPwd = event.target.value;
      if (confirmPwd !== '' && confirmPwd === pwd) {
        setErrorConfirmPassword(false);
        setSuccessConfirmPassword('success');
        setFocusedConfirmPassword(true);
        setHelperTextConfirmPassword('Passwörter Stimmen überein');
      } else if (confirmPwd === '') {
        setErrorConfirmPassword(false);
        setHelperTextConfirmPassword('');
        setSuccessConfirmPassword('primary');
      } else {
        setErrorConfirmPassword(true);
        setHelperTextConfirmPassword('Passwörter stimmen nicht überein');
      }
    },
    [pwd],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const user: SignInFormData = {
        password: data?.get('password')?.toString() ?? '',
        passwordConfirm: data?.get('passwordConfirm')?.toString() ?? '',
      };

      const isMatchedPwd = user.password === user.passwordConfirm;
      const isEmptyPwd = user.password === '';
      const isEmptyConfirmPwd = user.passwordConfirm === '';
      if (isEmptyPwd || isEmptyConfirmPwd) {
        handleSnackBar(true, 'Die Felder dürfen nicht leer sein', 'error');
      } else if (isMatchedPwd && !errorPassword && !errorConfirmPassword) {
        api
          .put('/users/resetPassword', {
            token,
            email: mailUser,
            password: user.password,
          })
          .then(response => {
            console.log(response);
          })
          .catch((error: any) => {
            console.error(error.response.data.message);
          });
        handleSnackBar(true, 'Passwort erfolgreich zurückgesetzt', 'success');
        setTimeout(() => {
          navigate('/login');
        }, 1000);
      } else {
        handleSnackBar(true, 'Passwörter müssen übereinstimmen', 'error');
      }
    },
    [
      errorConfirmPassword,
      errorPassword,
      handleSnackBar,
      mailUser,
      navigate,
      token,
    ],
  );

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }
    handleSnackBar(false, '', '');
  };

  return (
    <>
      {mailUser ? (
        <ThemeProvider theme={createTheme()}>
          <Content>
            <Container
              component="main"
              maxWidth="xs"
              style={{
                border: '1px solid #DAE2ED',
                padding: 22,
                borderRadius: 12,
                marginTop: 25,
                maxWidth: 'none',
                width: '50%',
                height: '80%',
                alignContent: 'center',
              }}
            >
              <Box
                sx={{
                  marginTop: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar> */}
                <Typography component="h1" variant="h5">
                  Passwort zurücksetzen
                </Typography>
                <br />
                <span>Geben Sie ein neues Passwort für {mailUser} ein</span>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <PwdRequirements>
                    <span>Ihr Passwort muss enthalten:</span>
                    <ul style={{ marginTop: 5, paddingInlineStart: '35px' }}>
                      <li>Mindestens 12 Zeichen </li>
                      <li>Sonderzeichen</li>
                      <li>Zahl</li>
                      <li>Großbuchstabe</li>
                    </ul>
                  </PwdRequirements>
                  <TextField
                    focused={focusedPassword}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Passwort"
                    error={errorPassword}
                    helperText={helperTextPassword}
                    onChange={checkSecurityPassword}
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    color={successPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoFocus
                  />
                  <TextField
                    focused={focusedConfirmPassword}
                    margin="normal"
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Passwort bestätigen"
                    error={errorConfirmPassword}
                    helperText={helperTextConfirmPassword}
                    onChange={checkMatchedPassword}
                    type={showPassword ? 'text' : 'password'}
                    id="passwordConfirm"
                    color={successConfirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="current-password"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // onClick={handleSubmit}
                  >
                    Passwort zurücksetzen
                  </Button>
                </Box>
              </Box>
            </Container>
          </Content>
        </ThemeProvider>
      ) : (
        <Modal
          content={'invalidToken'}
          invalidTokenProp={{ open: true }}
        ></Modal>
      )}
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;

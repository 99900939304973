/* eslint-disable no-shadow */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import { fixedWidgets } from '../../utils/fixedWidgets';
import { Widget } from '../../pages/UserGroupsPage';
import { AddUrlDownloads, DownloadItem } from '../addUrlDownloads';
import { Container, TreeViewContainer, WidgetsDetails } from './styles';
import EBTreeView from '../EBTreeView';

enum EnumWidgetType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

interface Props {
  widgetsList: Widget[];
  setWidgetsList: any;
  usergroupId: number;
  addFileIdsToDeleteList: any;
}

const WidgetsBox: React.FC<Props> = ({
  widgetsList,
  setWidgetsList,
  usergroupId,
  addFileIdsToDeleteList,
}) => {
  const [downloadItemList, setDownloadItemList] = useState<DownloadItem[]>([]);
  const [downloadItemUrlsToDelete, setDownloadItemUrlsToDelete] = useState<
    string[]
  >([]);

  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const [widgetName, setWidgetName] = useState<string>('');
  const [widgetUrl, setWidgetUrl] = useState<string>('');
  const [widgetUrlImage, setWidgetUrlImage] = useState<string>('');
  const [widgetType, setWidgetType] = useState<string>(EnumWidgetType.EXTERNAL);
  const [description, setDescription] = useState<string>('');
  const [required, setRequired] = useState<any>({});
  const [showWidgetDetails, setShowWidgetDetails] = useState<boolean>(false);
  const [widgetParent, setWidgetParent] = useState<any>();

  const hasRequiredFields = useCallback((form: any): boolean => {
    const requiredObj: any = {};
    if (form.widgetName?.trim() === '') {
      requiredObj.widgetName = true;
    }

    // if (form.widgetUrl?.trim() === '') {
    //   requiredObj.widgetUrl = true;
    // }

    if (form.internalWidget?.trim() === '') {
      requiredObj.internalWidget = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const insertNewWidget = useCallback(
    (node: any, widgetToInsert: any, widgetParent: any) => {
      if (node.widgetName === widgetParent.widgetName) {
        const newNode = { ...node };

        if (newNode.items) {
          newNode.items.push(widgetToInsert);
        } else {
          newNode.items = [widgetToInsert];
        }

        return newNode;
      }

      const newItems = node.items.map((node: any) => {
        const newNode = { ...node };

        if (node.widgetName === widgetParent.widgetName) {
          if (node.items) {
            newNode.items.push(widgetToInsert);
          } else {
            newNode.items = [widgetToInsert];
          }
        } else if (node.items) {
          return insertNewWidget(node, widgetToInsert, widgetParent);
        }

        return newNode;
      });

      return { ...node, items: newItems };
    },
    [],
  );

  const deleteWidget = useCallback((node: any, widgetToDelete: any) => {
    const newItems = node.items?.map((no: any) => {
      return deleteWidget(no, widgetToDelete);
    });

    const filtered = newItems?.filter(
      (item: any) => item.widgetName !== widgetToDelete.widgetName,
    );

    return { ...node, items: filtered };
  }, []);

  const editWidget = useCallback(
    (node: any, widgetToEdit: any, widgetForm: any) => {
      if (node.widgetName === widgetToEdit.widgetName) {
        return { ...widgetForm, items: node.items };
      }

      const newItems = node.items?.map((item: any) => {
        return editWidget(item, widgetToEdit, widgetForm);
      });

      return { ...node, items: newItems };
    },
    [],
  );

  const cleanWidgetBoxDetails = useCallback(() => {
    setWidgetName('');
    setWidgetUrl('');
    setWidgetUrlImage('');
    setDescription('');
    setDownloadItemList([]);
    setDownloadItemUrlsToDelete([]);
    setSelectedWidget(undefined);
  }, []);

  const handleSaveWidget = useCallback(() => {
    const widgetForm: any = {
      widgetName,
      widgetUrl,
      widgetUrlImage,
      description,
      type: widgetType,
      downloadItems: downloadItemList,
    };

    if (widgetForm.widgetUrl !== '/downloads') {
      if (widgetForm.downloadItems.length > 0) {
        addFileIdsToDeleteList(
          widgetForm?.downloadItems?.map(
            (downloadItem: DownloadItem) => downloadItem.fileId,
          ) ?? [],
        );
      }

      delete widgetForm.downloadItems;
    }

    if (!hasRequiredFields(widgetForm)) {
      const objWidgets = { widgetName: 'widgets list', items: widgetsList };

      let updatedTree;
      if (selectedWidget) {
        updatedTree = editWidget(objWidgets, selectedWidget, widgetForm);
      } else {
        updatedTree = insertNewWidget(objWidgets, widgetForm, widgetParent);
      }

      setWidgetsList(updatedTree.items);

      setShowWidgetDetails(false);
      cleanWidgetBoxDetails();
      setRequired({});
    }
  }, [
    widgetName,
    widgetUrl,
    widgetUrlImage,
    description,
    widgetType,
    downloadItemList,
    hasRequiredFields,
    addFileIdsToDeleteList,
    widgetsList,
    selectedWidget,
    setWidgetsList,
    cleanWidgetBoxDetails,
    editWidget,
    insertNewWidget,
    widgetParent,
  ]);

  const openWidgetForm = useCallback(
    (node: any, isAdding: boolean) => {
      if (isAdding) {
        cleanWidgetBoxDetails();
        setWidgetParent(node);
      } else {
        setSelectedWidget(node);
      }

      setShowWidgetDetails(true);
    },
    [cleanWidgetBoxDetails],
  );

  const handleCancel = useCallback(() => {
    cleanWidgetBoxDetails();
    setShowWidgetDetails(false);
  }, [cleanWidgetBoxDetails]);

  const handleDeleteWidget = useCallback(() => {
    addFileIdsToDeleteList(
      selectedWidget?.downloadItems?.map(downloadItem => downloadItem.fileId) ??
        [],
    );
    const objWidgets = { widgetName: 'widgets list', items: widgetsList };

    const updatedTree = deleteWidget(objWidgets, selectedWidget);

    setWidgetsList(updatedTree.items);

    setShowWidgetDetails(false);
    cleanWidgetBoxDetails();
  }, [
    cleanWidgetBoxDetails,
    deleteWidget,
    selectedWidget,
    addFileIdsToDeleteList,
    setWidgetsList,
    widgetsList,
  ]);

  useEffect(() => {
    if (selectedWidget) {
      setShowWidgetDetails(true);
      setWidgetName(selectedWidget.widgetName);
      setWidgetUrl(selectedWidget.widgetUrl);
      setWidgetUrlImage(selectedWidget.widgetUrlImage);
      setWidgetType(selectedWidget.type);
      setDescription(selectedWidget.description);
      setDownloadItemList(selectedWidget.downloadItems ?? []);
    }
  }, [selectedWidget]);

  const showInternalWidgetSelect = widgetType === EnumWidgetType.INTERNAL;
  const internalWidgetType = showInternalWidgetSelect
    ? fixedWidgets.find(x => x.widgetUrl === widgetUrl)
    : '';
  const showAddDownloadWidget =
    showInternalWidgetSelect && widgetUrl === '/downloads';

  return (
    <>
      <Container>
        <TreeViewContainer>
          <EBTreeView
            data={widgetsList}
            setData={setWidgetsList}
            onAddItem={openWidgetForm}
            onEditItem={openWidgetForm}
          ></EBTreeView>
        </TreeViewContainer>
        <WidgetsDetails>
          {showWidgetDetails && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '94%',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <h3
                  style={{
                    margin: 'unset',
                    marginBottom: '1vh',
                    marginTop: '0.5vh',
                    paddingLeft: '3px',
                  }}
                >
                  {selectedWidget ? selectedWidget.widgetName : 'New widget'}
                </h3>
              </div>
              <FormControl
                fullWidth
                style={{
                  width: '94%',
                  alignSelf: 'center',
                  margin: '8px 0 4px 0',
                }}
              >
                <InputLabel id="widgetTypeSelect-label">Type</InputLabel>
                <Select
                  labelId="widgetTypeSelect-label"
                  id="widgetTypeSelect"
                  name="widgetType"
                  label="Type"
                  value={widgetType}
                  onChange={event => {
                    setWidgetUrl('');
                    setWidgetType(event.target.value);
                  }}
                >
                  <MenuItem key={0} value={EnumWidgetType.INTERNAL}>
                    Internal
                  </MenuItem>
                  <MenuItem key={1} value={EnumWidgetType.EXTERNAL}>
                    External
                  </MenuItem>
                </Select>
              </FormControl>
              {showInternalWidgetSelect && (
                <FormControl
                  fullWidth
                  style={{
                    width: '94%',
                    alignSelf: 'center',
                    margin: '8px 0 4px 0',
                  }}
                >
                  <InputLabel id="selectInternalWidget-label">
                    Internal Widget
                  </InputLabel>
                  <Select
                    value={JSON.stringify(internalWidgetType)}
                    error={!!required.internalWidget}
                    labelId="selectInternalWidget-label"
                    label="Internal Widget"
                    id="selectInternalWidget"
                    name="internalWidget"
                    onChange={event => {
                      setWidgetUrl(
                        JSON.parse(event.target.value as string).widgetUrl,
                      );
                      const req = required;
                      req.internalWidget = false;
                      setRequired(req);
                    }}
                  >
                    {fixedWidgets.map(internalWidget => {
                      return (
                        <MenuItem
                          key={internalWidget.widgetName}
                          value={JSON.stringify(internalWidget)}
                        >
                          {internalWidget.widgetName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {showAddDownloadWidget && (
                <div
                  style={{
                    width: '94%',
                    alignSelf: 'center',
                    margin: '10px 0',
                  }}
                >
                  <AddUrlDownloads
                    downloadItemsList={downloadItemList}
                    setDownloadItemList={setDownloadItemList}
                    downloadItemsUrlToDelete={downloadItemUrlsToDelete}
                    setDownloadItemsUrlToDelete={setDownloadItemUrlsToDelete}
                    isEdit={false}
                    usergroupId={usergroupId}
                  ></AddUrlDownloads>
                </div>
              )}
              <TextField
                margin="dense"
                id="widgetName"
                name="widgetName"
                value={widgetName}
                onChange={event => {
                  setWidgetName(event.target.value);
                  if (event.target.value?.trim()) {
                    const req = required;
                    req.widgetName = false;
                    setRequired(req);
                  }
                }}
                label="Widget Name"
                type="text"
                variant="outlined"
                error={!!required.widgetName}
                helperText={required.widgetName ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                margin="dense"
                id="widgetUrl"
                name="widgetUrl"
                value={widgetUrl}
                onChange={event => {
                  setWidgetUrl(event.target.value);
                  const req = required;
                  req.widgetUrl = false;
                  setRequired(req);
                }}
                label="URL"
                type="text"
                variant="outlined"
                error={!!required.widgetUrl}
                helperText={required.widgetUrl ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
                disabled={widgetType === EnumWidgetType.INTERNAL}
              />
              <TextField
                margin="dense"
                id="imageUrl"
                name="imageUrl"
                value={widgetUrlImage}
                onChange={event => setWidgetUrlImage(event.target.value)}
                label="Image URL"
                type="text"
                variant="outlined"
                error={!!required.imageUrl}
                helperText={required.imageUrl ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
              />
              <TextField
                margin="dense"
                id="description"
                name="description"
                value={description}
                onChange={event => setDescription(event.target.value)}
                label="Description"
                type="text"
                variant="outlined"
                error={!!required.description}
                helperText={required.description ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
                inputProps={{ maxLength: 100 }}
              />

              {selectedWidget ? (
                <div
                  style={{
                    width: '94%',
                    height: '7%',
                    alignSelf: 'center',
                    paddingTop: '7px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <Button
                      style={{ background: '#ff3737' }}
                      onClick={handleDeleteWidget}
                      variant="contained"
                    >
                      Delete widget
                    </Button>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button onClick={handleCancel} variant="contained">
                      Cancel
                    </Button>
                    <Button onClick={handleSaveWidget} variant="contained">
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: '94%',
                    height: '7%',
                    alignSelf: 'center',
                    paddingTop: '7px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                  }}
                >
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button onClick={handleCancel} variant="contained">
                      Cancel
                    </Button>
                    <Button onClick={handleSaveWidget} variant="contained">
                      Add widget
                    </Button>
                  </div>
                </div>
              )}
            </Box>
          )}
        </WidgetsDetails>
      </Container>
    </>
  );
};

export default WidgetsBox;

/* eslint-disable no-shadow */
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Widget } from '../../../pages/UserGroupsPage';
import WidgetsBox from '../../widgetsBox';
import api from '../../../services/api';

interface props {
  setOpen: any;
  widgetsListForm: Widget[];
  handleCloseOptions?: any;
  handleAdd?: any;
  getUserGroups?: any;
  handleSaveEdit?: any;
  handleSaveSettings?: any;
  usergroup?: any;
  handleClose: any;
  countUser: any;
}
const SettingsUserGroupContent: React.FC<props> = ({
  setOpen,
  handleCloseOptions = undefined,
  handleClose,
  handleSaveSettings,
  usergroup = undefined,
}) => {
  const [userGroupNameForm, setUserGroupNameForm] = useState<string>('');
  const [videoLinkForm, setVideoLinkForm] = useState<string>('');

  const [externalWidgetsList, setExternalWidgetsList] = useState<Widget[]>([]);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<number[]>([]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);
      const settings = {
        videoLink: formdata?.get('videoLink')?.toString() ?? '',
        widgets: externalWidgetsList,
      };

      const userGroupToUpdate = { ...usergroup, settings };

      handleSaveSettings(userGroupToUpdate, fileIdsToDelete);

      setOpen(false);
      handleCloseOptions();
    },
    [
      externalWidgetsList,
      fileIdsToDelete,
      handleCloseOptions,
      handleSaveSettings,
      setOpen,
      usergroup,
    ],
  );

  const handleCancel = useCallback((): void => {
    setUserGroupNameForm('');

    setUserGroupNameForm('');

    setExternalWidgetsList([]);
    setOpen(false);
    if (usergroup) {
      handleCloseOptions();
    }
    handleClose();

    // Delete files that were uploaded but not saved
    if (fileIdsToDelete.length > 0) {
      try {
        const queryString = fileIdsToDelete
          .map(id => `fileIdsToDelete=${id}`)
          .join('&');
        api.delete(`/files/deleteByIds?${queryString}`);
      } catch (error: any) {
        console.error('Error deleting files', error.response.data.message);
      }
    }
  }, [fileIdsToDelete, handleClose, handleCloseOptions, setOpen, usergroup]);

  useEffect(() => {
    if (usergroup) {
      setUserGroupNameForm(usergroup.name);
      setVideoLinkForm(usergroup?.settings?.videoLink);
      setExternalWidgetsList(
        usergroup?.settings?.widgets.map((x: any) => x) ?? [],
      );
    }
  }, [usergroup]);

  const addFileIdsToDeleteList = (fileIds: number[]): void => {
    // Files to be deleted on save
    setFileIdsToDelete([...fileIdsToDelete, ...fileIds]);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} style={{ width: '50vw' }}>
        <DialogTitle>{`User Group Settings: ${userGroupNameForm}`}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '2%',
            }}
          >
            <TextField
              margin="dense"
              id="userGroupName"
              name="userGroupName"
              value={userGroupNameForm}
              onChange={event => setUserGroupNameForm(event.target.value)}
              label="User Group Name"
              type="text"
              variant="outlined"
              disabled={true}
              style={{ marginBottom: '20px', width: '50%' }}
              inputProps={{ maxLength: 50 }}
            />

            <FormControl style={{ marginBottom: '20px', width: '50%' }}>
              <TextField
                margin="dense"
                id="videoLink"
                name="videoLink"
                value={videoLinkForm}
                onChange={event => setVideoLinkForm(event.target.value)}
                label="Video (link to video shown at initial page)"
                type="text"
                variant="outlined"
              />
            </FormControl>
          </div>

          <WidgetsBox
            widgetsList={externalWidgetsList}
            setWidgetsList={setExternalWidgetsList}
            usergroupId={usergroup.userGroupId} // TODO: PREENCHER ISSO COM O USERGROUP REAL
            addFileIdsToDeleteList={addFileIdsToDeleteList}
          ></WidgetsBox>
          {/* <AddWigetsBox
            widgetsList={externalWidgetsList}
            setWidgetsList={setExternalWidgetsList}
            widgetsToDelete={widgetsToDelete}
            setWidgetsToDelete={setWidgetsToDelete}
            isEdit={!!usergroup}
          ></AddWigetsBox> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit">{usergroup ? 'Save All' : 'Add'}</Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default SettingsUserGroupContent;

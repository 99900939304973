import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
// import AdbIcon from '@mui/icons-material/Adb';
import { useState } from 'react';
import { Link } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { Logo, UserName } from './styles';
import { Page } from '../mainLayout';

interface Props {
  pages: Page[];
}

const layoutDefault = {
  urlLogo:
    'https://api.meine-sicht.com/files/836654b1d642bb4672a2-1_Logo_transparenter%20Hintergrund.png',
  mainColor: 'blueviolet',
};

const MenuAppBar: React.FC<Props> = ({ pages }) => {
  const [anchorUser, setAnchorUser] = useState<null | HTMLElement>(null);
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);

  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const handleUser = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorUser(event.currentTarget);
  };

  const handleOptions = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOptions(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorOptions(null);
    setAnchorUser(null);
  };

  const handleApply = (page: Page): void => {
    handleClose();
    navigate(page.route);
  };

  const clickLogo = (): void => {
    if (pages.length > 0) {
      navigate('/');
    }
  };

  const handleLogOut = (): void => {
    signOut();
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          display: 'flex',
          marginBottom: '1px',

          background:
            user.mainColor && user.mainColor !== ''
              ? user.mainColor
              : layoutDefault.mainColor,

          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px 0px',
        }}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div id="options" style={{ display: 'flex' }}>
            {pages.length > 0 && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleOptions}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  sx={{ mt: '35px', left: '10px' }}
                  id="menu-appbar"
                  anchorEl={anchorOptions}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorOptions)}
                  onClose={handleClose}
                >
                  {pages.map((page: Page, index) => (
                    <MenuItem key={index} onClick={() => handleApply(page)}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
                <Link href="/">
                  <HomeIcon style={{ color: 'white' }} sx={{ mt: 1.5 }} />
                </Link>
              </>
            )}
          </div>
          <Logo>
            <img
              src={
                user.urlLogo && user.urlLogo !== ''
                  ? user.urlLogo
                  : layoutDefault.urlLogo
              }
              alt="Logo"
              style={{ maxHeight: '45px', maxWidth: '100%', cursor: 'pointer' }}
              onClick={clickLogo}
            />
          </Logo>
          <div id="userAvatar" style={{ display: 'flex', gap: '10px' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleUser}
              color="inherit"
            >
              <AccountCircle />
              <UserName>
                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
              </UserName>
            </IconButton>
            <Menu
              sx={{ mt: '35px', left: '-25px' }}
              id="menu-appbar"
              anchorEl={anchorUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorUser)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogOut}>LogOut</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuAppBar;

import React from 'react';
import { Step } from 'react-joyride';

const joyrideStyles = {
  options: {
    zIndex: 10001,
  },
};

export const tutorialMobileSteps: Step[] = [
  {
    target: 'body',
    title: 'Willkommen!',
    content:
      'Beginnen wir mit einer Übersicht und Erläuterung der To-Do-Liste.',
    disableBeacon: true,
    styles: joyrideStyles,
    placement: 'center' as const,
  },
  {
    target: '#create-task-button',
    title: 'Erstellen Sie eine neue Aufgabe.',
    content: (
      <div>
        Wählen Sie das folgende Symbol{' '}
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '15px',
            height: '15px',
            padding: '2px',
            borderRadius: '50%',
            fontSize: '11px',
            color: 'white',
            backgroundColor: 'rgb(25, 118, 210)',
          }}
        >
          +
        </span>
        , um eine neue Aufgabe zu erstellen.
      </div>
    ),

    placement: 'center' as const,
    styles: joyrideStyles,
  },
  {
    target: '#header',
    title: 'Neue Aufgabe.',
    content:
      'Erstellen Sie eine neue Aufgabe, indem Sie die folgenden Informationen eingeben.',
    placement: 'center' as const,
    styles: joyrideStyles,
  },
  {
    target: '#body-form',
    title: 'Details zur Aufgabe.',
    content:
      'Wählen Sie zudem die Organisationseinheit und die verantwortlichen Personen aus. Außerdem können Sie den Aufwand, den Impact und den aktuellen Status bestimmen.',
    placement: 'center' as const,
    styles: joyrideStyles,
  },
  {
    target: '#guest-container',
    title: 'Fügen Sie Gäste zu einer Aufgabe hinzu.',
    content:
      'Um eine neue Person zur Aufgabe hinzuzufügen, wählen Sie unter Gäste das Symbol (+).',
    placement: 'center' as const,
    styles: joyrideStyles,
  },
  {
    target: '.MuiDataGrid-topContainer',
    title: 'Übersicht über alle Aufgaben.',
    content: (
      <span>
        Alle hinzugefügten Aufgaben werden hier aufgelistet und können über die
        entsprechenden Symbole gefiltert (
        <svg
          style={{ verticalAlign: 'middle', color: 'grey', width: '15px' }}
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="TripleDotsVerticalIcon"
        >
          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
        </svg>
        ) und sortiert (
        <svg
          style={{ verticalAlign: 'middle', color: 'grey', width: '15px' }}
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-ptiqhd-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ArrowUpwardIcon"
        >
          <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path>
        </svg>
        ) werden.
      </span>
    ),
    placement: 'bottom' as const,
    // styles: joyrideStyles,
  },
  {
    target: '#drawer-toggle-button',
    title: 'Menü öffnen.',
    content:
      'Erweitern Sie das Menü, um zwischen der To-Do-Liste und der Entscheidungsmatrix zu wechseln.',
    placement: 'left-start' as const,
    styles: joyrideStyles,
  },
  {
    target: '#decision-matrix-menu-item-mobile',
    title: 'Wechsel der Übersicht.',
    content:
      "Im linken Menüfeld können Sie zwischen den To-Do's und der Entscheidungsmatrix wechseln.",
    placement: 'right-start' as const,
    styles: joyrideStyles,
  },
  {
    target: '#do-it-quadrant',
    title: 'Entscheidungsmatrix',
    content:
      'Klicken Sie auf die Felder der Entscheidungsmatrix, um die Aufgaben aus der To-Do-Liste zu sehen.',
    placement: 'right-end' as const,
    styles: joyrideStyles,
  },
  {
    target: '#help-button-mobile',
    title: 'Tutorial beenden.',
    content: (
      <span>
        Sie können nun neue Aufgaben hinzufügen. Wenn Sie weitere Hilfe
        benötigen, können Sie die Schritte auf dem Hilfesymbol{' '}
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '15px',
            height: '15px',
            padding: '2px',
            borderRadius: '50%',
            fontSize: '11px',
            color: 'white',
            backgroundColor: 'rgb(25, 118, 210)',
          }}
        >
          ?
        </span>{' '}
        erneut aufrufen.
      </span>
    ),
    placement: 'center' as const,
    styles: joyrideStyles,
  },
];

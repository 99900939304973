import React, { createContext, useCallback, useContext } from 'react';
import { useAuth } from './authContext';
import api from '../services/api';

interface LogContextData {
  logger(type: string, action: string, message: string): Promise<boolean>;
}

interface Props {
  children: React.ReactNode;
}

const LoggerContext = createContext<LogContextData>({} as LogContextData);

const LoggerProvider: React.FC<Props> = ({ children }) => {
  const { user } = useAuth();

  const logger = useCallback(
    async (type: string, action: string, message: string) => {
      try {
        const log = {
          user: user ? user.email : 'no user',
          createdAt: new Date(),
          type,
          action,
          message,
        };

        api.post('/logs', log).catch((error: any) => {
          console.error(error.response.data.message);
        });

        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [user],
  );

  return (
    <LoggerContext.Provider value={{ logger }}>
      {children}
    </LoggerContext.Provider>
  );
};

function useLogger(): LogContextData {
  const context = useContext(LoggerContext);

  return context;
}

export { LoggerProvider, useLogger };

import styled from 'styled-components';

export const Title = styled('div')(() => ({
  width: '30%',
  background: '#1976d2',
  color: 'white',
  textAlign: 'center',
  borderRadius: '5px',
  paddingTop: '8px',
}));

export const SelectFileDiv = styled.div`
  flex-grow: 4;

  width: 100%;
  min-width: 300px;

  label {
    font-weight: 500;
    cursor: pointer;
  }
  input {
    width: 100%;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 15px 16px 8px 16px;
    color: #312e38;
    display: inline-block;
    margin: 8px 13px 6px 0;
  }
`;

export const ButtonUpload = styled.div`
  flex-grow: 1;
  border-radius: 7px;
  min-width: 300px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 15px 16px 8px 16px;
  color: #312e38;
  display: inline-block;
  width: 130px;
  margin: 8px 13px 6px 8px;

  label {
    font-weight: 500;
    cursor: pointer;

    input {
      display: none;
    }
  }

  &:hover {
    background: rgb(230, 130, 0);
  }
`;

import React, { useEffect, useState, JSX, useCallback } from 'react';
import { Select } from 'antd';
import SupersetDashboard from '../../components/superset-dashboard/superset-dashboard';
import { DashboardBodyBox, DashboardContainer } from './styles';
import api from '../../services/api';
import Loading from '../../components/loading';
import { useAuth } from '../../context/authContext';
import FilterUsergroup from '../../components/FilterUsergroup';
import OverlayUsergroupRequired from '../../components/OverlayUsergroupRequired';

const DashboardPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dashIds, setDashIds] = useState<any>({});
  const [idToUuidMap, setIdToUuidMap] = useState<Map<string, number>>();
  const [dashboardOptions, setDashboardOptions] = useState([
    { label: 'select a dashboard...', value: '' },
  ]);

  const { user } = useAuth();

  useEffect(() => {
    if (user.selectedUsergroup === undefined) return;
    const dashboardsIds = user.selectedUsergroup.dashboards;
    setIsLoading(true);
    api
      .post(`/superset/getDashboards`, { dashboardsIds })
      .then(response => {
        setIsLoading(false);
        const map = new Map();
        const dropdownDashboardList = response.data.map((dash: any) => {
          map.set(dash.uuid, dash.id);
          return {
            label: `${dash.dashboard_title}`,
            value: dash.uuid,
          };
        });
        setIdToUuidMap(map);
        setDashboardOptions(dropdownDashboardList);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error(error.response.data.message);
      });
  }, [user]);

  const handleChangeDashboard = (value: any): void => {
    const id = idToUuidMap?.get(value);
    const uuid = value;
    setDashIds({ uuid, id });
  };

  const dashboardSelector: JSX.Element = (
    <Select
      style={{ width: '25%', height: '100%' }}
      defaultValue={['select a dashboard...']}
      onChange={handleChangeDashboard}
      optionLabelProp="label"
      options={dashboardOptions}
    />
  );

  const onChangeUsergroup = useCallback(() => {
    setDashIds({});
  }, []);

  return (
    <>
      {!user.selectedUsergroup ? (
        <OverlayUsergroupRequired></OverlayUsergroupRequired>
      ) : (
        <>
          {isLoading ? (
            <>
              <FilterUsergroup>
                <div></div>
              </FilterUsergroup>
              <DashboardContainer>
                <Loading />
              </DashboardContainer>
            </>
          ) : (
            <FilterUsergroup onChangeUsergroup={onChangeUsergroup}>
              {dashboardSelector}
            </FilterUsergroup>
          )}
          {dashIds?.uuid && (
            <DashboardBodyBox id="dashboard-body-box">
              <SupersetDashboard dashIds={dashIds}></SupersetDashboard>
            </DashboardBodyBox>
          )}
        </>
      )}
    </>
  );
};

export default DashboardPage;

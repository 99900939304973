import React, { useEffect, createRef, useCallback } from 'react';

import { Button } from '@mui/material';
import { Container } from './styles';
import { useAuth } from '../../context/authContext';
import { DownloadItem } from '../../components/addUrlDownloads';
import FilterUsergroup from '../../components/FilterUsergroup';
import OverlayUsergroupRequired from '../../components/OverlayUsergroupRequired';

const DownloadFilesPage: React.FC = () => {
  const [downloadItems, setDownloadItems] = React.useState<DownloadItem[]>([]);
  const [selectedFiles, setSelectedFiles] = React.useState<DownloadItem[]>([]);

  const handleDownloadAll = (): void => {
    let timeout = 1200;
    downloadItems.forEach(file => {
      const link = document.createElement('a');
      link.href = decodeURI(file.url);
      // link.target = '_blank';
      link.download = file.description;
      setTimeout(() => {
        // console.log('clicking', link);
        link.click();
      }, timeout);
      timeout += 1200;
    });
  };

  const handleDownloadSelected = (): void => {
    let timeout = 1200;
    selectedFiles.forEach(file => {
      const link = document.createElement('a');
      link.href = decodeURI(`${file.url}?filename=${file.description}`);
      // link.target = '_blank';
      link.download = file.description;
      setTimeout(() => {
        link.click();
      }, timeout);
      timeout += 1200;
    });
  };

  const handleSelectFile = (file: DownloadItem, isChecked: boolean): any => {
    if (isChecked) {
      setSelectedFiles(prevSelected => [...prevSelected, file]);
    } else {
      setSelectedFiles(prevSelected => prevSelected.filter(f => f !== file));
    }
  };

  const handleButtonClick = (
    file: DownloadItem,
    inputRef: React.RefObject<HTMLInputElement>,
  ): void => {
    const wasChecked = selectedFiles.some(x => x === file);
    const isChecked = !wasChecked;
    if (inputRef?.current) {
      // eslint-disable-next-line no-param-reassign
      inputRef.current.checked = isChecked;
      handleSelectFile(file, isChecked);
    }
  };

  const { user } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const findDownloadWidgets = useCallback((widgetsList: any[]): any => {
    return widgetsList.reduce((selectedWidget, widget) => {
      if (selectedWidget) return selectedWidget;

      if (widget.widgetUrl === '/downloads') return widget;

      if (widget.items && widget.items.length > 0) {
        return findDownloadWidgets(widget.items);
      }

      return null;
    }, null);
  }, []);

  useEffect(() => {
    if (!user.selectedUsergroup) return;
    setIsLoading(true);

    const downloadWidget = findDownloadWidgets(
      user.selectedUsergroup.settings.widgets,
    );
    setDownloadItems(downloadWidget ? downloadWidget.downloadItems : []);
    setIsLoading(false);
  }, [findDownloadWidgets, user.selectedUsergroup]);

  return (
    <>
      {!user.selectedUsergroup ? (
        <OverlayUsergroupRequired></OverlayUsergroupRequired>
      ) : (
        <>
          {user.usergroups.length > 1 && <FilterUsergroup />}

          <Container>
            <h1>Downloads</h1>

            {isLoading && <p>Loading...</p>}
            {!isLoading && downloadItems.length === 0 && (
              <p>No files to download</p>
            )}

            <div>
              <div>
                {downloadItems.map((file, index) => {
                  const inputRef = createRef<HTMLInputElement>();
                  return (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={() => handleButtonClick(file, inputRef)}
                        style={{
                          justifyContent: 'left',
                          width: '100%',
                          maxWidth: '700px',
                          textTransform: 'none',
                        }}
                      >
                        <input
                          ref={inputRef}
                          type="checkbox"
                          style={{ marginRight: '5px' }}
                        />
                        {file.description}
                      </Button>
                    </div>
                  );
                })}
              </div>

              {selectedFiles.length > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleDownloadSelected}
                    style={{
                      textTransform: 'none',
                    }}
                  >
                    Download Selected
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleDownloadAll}
                    style={{
                      textTransform: 'none',
                    }}
                  >
                    Download All
                  </Button>
                </div>
              )}
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default DownloadFilesPage;

'use strict';

import './radialMenu.css';
import backButtonImg from '../../assets/backButton2.png';

var DEFAULT_SIZE = 100;
var MIN_SECTORS = 3;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function RadialMenu(params) {
  var self = this;

  self.parent = params.parent || [];

  self.size = params.size || DEFAULT_SIZE;
  self.onClick = params.onClick || null;
  self.handleDoubleClick = params.handleDoubleClick || null;
  self.onClose = params.onClose || null;
  self.menuItems = params.menuItems
    ? params.menuItems
    : [
        { id: 'one', title: 'One' },
        { id: 'two', title: 'Two' },
      ];

  self.radius = 45;
  self.innerRadius = self.radius * 0.4;
  self.sectorSpace = self.radius * 0.06;
  self.sectorCount = Math.max(self.menuItems.length, MIN_SECTORS);
  self.closeOnClick =
    params.closeOnClick !== undefined ? !!params.closeOnClick : false;

  self.scale = 1;
  self.holder = null;
  self.parentMenu = [];
  self.parentItems = [];
  self.levelItems = null;

  self.openWidget = false;

  self.createHolder();

  self.currentMenu = null;
  document.addEventListener('wheel', self.onMouseWheel.bind(self));
  document.addEventListener('keydown', self.onKeyDown.bind(self));
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.open = function () {
  var self = this;
  if (!self.currentMenu) {
    self.currentMenu = self.createMenu('menu inner', self.menuItems);
    self.holder.appendChild(self.currentMenu);

    // wait DOM commands to apply and then set class to allow transition to take effect
    RadialMenu.nextTick(function () {
      self.currentMenu.setAttribute('class', 'menu');
    });
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.getParentMenu = function () {
  var self = this;
  if (self.parentMenu.length > 0) {
    return self.parentMenu[self.parentMenu.length - 1];
  } else {
    return null;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createHolder = function () {
  var self = this;

  self.holder = document.createElement('div');
  self.holder.className = 'menuHolder';
  self.holder.style.width = '100%';
  self.holder.style.height = '100%';
  self.holder.style.display = 'flex';
  self.holder.style.alignItems = 'center';

  self.parent.appendChild(self.holder);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.showNestedMenu = function (item) {
  var self = this;
  self.parentMenu.push(self.currentMenu);
  self.parentItems.push(self.levelItems);
  self.currentMenu = self.createMenu('menu inner', item.items, true);
  self.holder.appendChild(self.currentMenu);

  // wait DOM commands to apply and then set class to allow transition to take effect
  RadialMenu.nextTick(function () {
    self.getParentMenu().setAttribute('class', 'menu outer');
    self.currentMenu.setAttribute('class', 'menu');
  });
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.returnToParentMenu = function () {
  var self = this;
  var parent = Array.from(self.getParentMenu().childNodes).find(
    el => el.getAttribute('class') === 'sector selected',
  );
  parent.setAttribute('open', false);
  self.getParentMenu().setAttribute('class', 'menu');
  RadialMenu.setClassAndWaitForTransition(self.currentMenu, 'menu inner').then(
    function () {
      self.currentMenu.remove();
      self.currentMenu = self.parentMenu.pop();
      self.levelItems = self.parentItems.pop();
    },
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.handleClick = function (isDoubleClick) {
  var self = this;

  var selectedIndex = self.getSelectedIndex();
  if (selectedIndex >= 0) {
    var item = self.levelItems[selectedIndex];
    if (item.items) {
      self.showNestedMenu(item);
    } else {
      if (self.handleDoubleClick && isDoubleClick) {
        self.handleDoubleClick(item);
      } else if (self.onClick) {
        self.onClick(item);
      }
    }
  }
};

RadialMenu.prototype.handleClose = function () {
  var self = this;

  var selectedIndex = self.getSelectedIndex();
  if (selectedIndex >= 0) {
    var item = self.levelItems[selectedIndex];
    if (item.items) {
      self.showNestedMenu(item);
    } else {
      if (self.onClose) {
        self.onClose(item);
      }
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.handleCenterClick = function () {
  var self = this;
  if (self.parentItems.length > 0) {
    self.returnToParentMenu();
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createCenter = function (svg, title, centerImg, size) {
  var self = this;
  size = size || 8;
  var g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g.setAttribute('class', 'center');

  var centerCircle = self.createCircle(
    0,
    0,
    self.innerRadius - self.sectorSpace / 3,
  );
  g.appendChild(centerCircle);

  // text in the center circle
  // var text = self.createText(0,0, title);
  // g.appendChild(text);

  if (centerImg) {
    var img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    img.setAttribute('href', backButtonImg);
    img.setAttribute('height', '20');
    img.setAttribute('width', '40');
    img.setAttribute('x', -20);
    img.setAttribute('y', -10);

    // var use = self.createUseTag(0,0, icon);
    // use.setAttribute('width', size);
    // use.setAttribute('height', size);
    // img.setAttribute('transform', 'translate(-' + RadialMenu.numberToString(size / 2) + ',-' + RadialMenu.numberToString(size / 2) + ')');

    g.appendChild(img);
  }

  svg.appendChild(g);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.getIndexOffset = function () {
  var self = this;
  if (self.levelItems.length < self.sectorCount) {
    switch (self.levelItems.length) {
      case 1:
        return -2;
      case 2:
        return -2;
      case 3:
        return -2;
      default:
        return -1;
    }
  } else {
    return -1;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createMenu = function (classValue, levelItems, nested) {
  var self = this;

  self.levelItems = levelItems;

  self.sectorCount = Math.max(self.levelItems.length, MIN_SECTORS);
  self.scale = self.calcScale();

  var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('class', classValue);
  svg.setAttribute('viewBox', '-60 -38 115 90');
  svg.setAttribute('width', '100%');
  svg.setAttribute('height', '100%');
  svg.setAttribute('style', 'max-height: 550px');

  var angleStep = 360 / self.sectorCount;
  var angleShift = angleStep / 2 + 270;

  var indexOffset = self.getIndexOffset();

  for (var i = 0; i < self.sectorCount; ++i) {
    var startAngle = angleShift + angleStep * i;
    var endAngle = angleShift + angleStep * (i + 1);

    var itemIndex = RadialMenu.resolveLoopIndex(
      self.sectorCount - i + indexOffset,
      self.sectorCount,
    );
    var item;
    if (itemIndex >= 0 && itemIndex < self.levelItems.length) {
      item = self.levelItems[itemIndex];
    } else {
      item = null;
    }

    self.appendSectorPath(startAngle, endAngle, svg, item, itemIndex);
  }

  if (nested) {
    self.createCenter(svg, '', true, 8);
  } else {
    self.createCenter(svg, '', false, 7);
  }

  svg.addEventListener('mousedown', function (event) {
    var parentClass = event.target.parentNode.getAttribute('class');
    if (parentClass) {
      var className = parentClass.split(' ')[0];
      switch (className) {
        case 'sector':
          var index = parseInt(
            event.target.parentNode.getAttribute('data-index'),
          );
          if (!isNaN(index)) {
            self.setSelectedIndex(index);
          }
          break;
        case 'center':
          self.handleCenterClick();
          break;
        default:
      }
    }
  });

  return svg;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.selectDelta = function (indexDelta) {
  var self = this;
  var selectedIndex = self.getSelectedIndex();
  if (selectedIndex < 0) {
    selectedIndex = 0;
  }
  selectedIndex += indexDelta;

  if (selectedIndex < 0) {
    selectedIndex = self.levelItems.length + selectedIndex;
  } else if (selectedIndex >= self.levelItems.length) {
    selectedIndex -= self.levelItems.length;
  }
  self.setSelectedIndex(selectedIndex);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.onKeyDown = function (event) {
  // var self = this;
  // if (self.currentMenu) {
  //     switch (event.key) {
  //         case 'Escape':
  //         case 'Backspace':
  //             self.handleCenterClick();
  //             event.preventDefault();
  //             break;
  //         case 'Enter':
  //             self.handleClick();
  //             event.preventDefault();
  //             break;
  //         case 'ArrowRight':
  //         case 'ArrowUp':
  //             self.selectDelta(1);
  //             event.preventDefault();
  //             break;
  //         case 'ArrowLeft':
  //         case 'ArrowDown':
  //             self.selectDelta(-1);
  //             event.preventDefault();
  //             break;
  //     }
  // }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.onMouseWheel = function (event) {
  var self = this;
  if (self.currentMenu) {
    var delta = -event.deltaY;

    if (delta > 0) {
      self.selectDelta(1);
    } else {
      self.selectDelta(-1);
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.getSelectedNode = function () {
  var self = this;
  var items = self.currentMenu.getElementsByClassName('selected');
  if (items.length > 0) {
    return items[0];
  } else {
    return null;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.getSelectedIndex = function () {
  var self = this;
  var selectedNode = self.getSelectedNode();
  if (selectedNode) {
    return parseInt(selectedNode.getAttribute('data-index'));
  } else {
    return -1;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.setSelectedIndex = function (index) {
  var self = this;
  if (index >= 0 && index < self.levelItems.length) {
    var items = self.currentMenu.querySelectorAll(
      'g[data-index="' + index + '"]',
    );
    if (items.length > 0) {
      var itemToSelect = items[0];
      var selectedNode = self.getSelectedNode();

      if (selectedNode) {
        selectedNode.setAttribute('class', 'sector');
      }
      itemToSelect.setAttribute('class', 'sector selected');
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createUseTag = function (x, y, link) {
  var use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  use.setAttribute('x', RadialMenu.numberToString(x));
  use.setAttribute('y', RadialMenu.numberToString(y));
  use.setAttribute('width', '10');
  use.setAttribute('height', '10');
  use.setAttribute('fill', 'white');
  use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', link);
  return use;
};

RadialMenu.prototype.calculateTextPosition = function (
  title,
  startAngleDeg,
  endAngleDeg,
) {
  var middle = (endAngleDeg - startAngleDeg) / 2;
  var newAngle = startAngleDeg + middle;

  var cos = Math.cos(RadialMenu.degToRad(newAngle));
  var sin = Math.sin(RadialMenu.degToRad(newAngle));

  var total = (this.radius - this.innerRadius) / 2;
  var length = this.innerRadius + total;

  if (title === 'Change') {
    return {
      x: sin * length + 1,
      y: cos * length - 5,
    };
  }

  if (title === 'Beratung') {
    return {
      x: sin * length,
      y: cos * length + 3,
    };
  }

  return {
    x: sin * length,
    y: cos * length,
  };
};

function isDoubleClick(event) {
  return event.detail && event.detail === 2;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.appendSectorPath = function (
  startAngleDeg,
  endAngleDeg,
  svg,
  item,
  index,
) {
  var self = this;

  var centerPoint = self.getSectorCenter(startAngleDeg, endAngleDeg);
  var translate = {
    x: (1 - self.scale) * centerPoint.x,
    y: (1 - self.scale) * centerPoint.y,
  };

  var g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  g.setAttribute('open', false);
  g.setAttribute(
    'transformAux',
    `translate(${translate.x},${translate.y}) scale(${self.scale})`,
  );
  g.setAttribute(
    'transform',
    `translate(${translate.x},${translate.y}) scale(${self.scale})`,
  );

  g.addEventListener('click', function (event) {
    var sectorClass = event.target.parentNode.getAttribute('class');
    if (sectorClass !== 'dummy') {
      var newTranslate = {
        x: (1 - self.scale) * (centerPoint.x * 2),
        y: (1 - self.scale) * (centerPoint.y * 2),
      };

      var options =
        event.target.parentNode.parentNode.getElementsByClassName('sector');
      var g = event.target.parentNode;
      var open = event.target.parentNode.getAttribute('open');

      if (isDoubleClick(event)) {
        self.handleClick(true);
        return;
      }

      if (open === 'true') {
        g.setAttribute('open', false);
        if (!item.items || item.items.length === 0) {
          g.setAttribute('transform', g.getAttribute('transformAux'));
        }
        g.setAttribute('fill', '#5f87b750');

        var gCenter =
          event.target.parentNode.parentNode.getElementsByClassName(
            'center',
          )[0];
        var lastChild = gCenter.lastElementChild;
        if (lastChild.localName === 'defs') {
          gCenter.removeChild(lastChild);
        }

        self.handleClose();
      } else {
        // check if other options are open and close them
        for (const option of options) {
          if (
            option.getAttribute('open') === 'true' &&
            option.dataset.id !== item.id
          ) {
            option.setAttribute('open', false);
            option.setAttribute(
              'transform',
              option.getAttribute('transformAux'),
            );
            option.setAttribute('fill', '#5f87b750');
            self.handleClose();
          }
        }

        var gCenter =
          event.target.parentNode.parentNode.getElementsByClassName(
            'center',
          )[0];
        var lastChild = gCenter.lastElementChild;
        if (lastChild.localName === 'defs') {
          gCenter.removeChild(lastChild);
        }

        var image = event.target.parentNode.getAttribute('image');
        var img = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'image',
        );
        img.setAttribute('href', image);
        img.setAttribute('height', '31');
        img.setAttribute('width', '31');
        img.setAttribute('x', 1.8);
        img.setAttribute('y', 1.5);

        var defs = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'defs',
        );
        var pattern = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'pattern',
        );
        pattern.setAttribute('id', 'pic');
        pattern.setAttribute('width', 1);
        pattern.setAttribute('height', 1);
        pattern.appendChild(img);

        defs.appendChild(pattern);
        gCenter.appendChild(defs);

        g.setAttribute('open', true);
        if (!item.items || item.items.length === 0) {
          g.setAttribute(
            'transform',
            `translate(${newTranslate.x},${newTranslate.y}) scale(${self.scale})`,
          );
        }
        g.setAttribute('fill', '#5f87b785');
        self.handleClick();
      }
    }
  });
  g.addEventListener('mouseover', function (event) {
    var open = event.target.parentNode.getAttribute('open');

    var brothers = Array.from(svg.childNodes);
    var openedBrothers = brothers.filter(
      g => g.getAttribute('open') === 'true',
    );

    if (open === 'false' && openedBrothers.length === 0) {
      //cleaning all defs to display the image in the center
      var allDefs = document.querySelectorAll('defs');
      var defsList = Array.from(allDefs);
      for (defs of defsList) {
        var parent = defs.parentElement;
        parent.removeChild(defs);
      }

      //removing the back button image
      var gCenter = brothers.filter(
        g => g.getAttribute('class') === 'center',
      )[0];
      var lastChild = gCenter.lastChild;
      if (lastChild.tagName === 'image') {
        gCenter.setAttribute('image', true);
        gCenter.removeChild(lastChild);
      }

      var image = event.target.parentNode.getAttribute('image');
      if (image) {
        var img = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'image',
        );
        img.setAttribute('href', image);
        img.setAttribute('height', '31');
        img.setAttribute('width', '31');
        img.setAttribute('x', 1.8);
        img.setAttribute('y', 1.5);

        var gCenter =
          event.target.parentNode.parentNode.getElementsByClassName(
            'center',
          )[0];

        var defs = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'defs',
        );
        var pattern = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'pattern',
        );
        pattern.setAttribute('id', 'pic');
        pattern.setAttribute('width', 1);
        pattern.setAttribute('height', 1);
        pattern.appendChild(img);

        defs.appendChild(pattern);
        gCenter.appendChild(defs);

        var circle = gCenter.firstChild;
        circle.setAttribute('style', 'fill: url("#pic")');
      }
    }
  });
  g.addEventListener('mouseout', function (event) {
    var open = event.target.parentNode.getAttribute('open');
    // var openedBrothers = document.querySelectorAll('g[open="true"]')
    var brothers = Array.from(svg.childNodes);
    var openedBrothers = brothers.filter(
      g => g.getAttribute('open') === 'true',
    );
    if (open === 'false' && openedBrothers.length === 0) {
      var gCenter =
        event.target.parentNode.parentNode.getElementsByClassName('center')[0];
      var lastChild = gCenter.lastElementChild;
      if (lastChild.localName === 'defs') {
        gCenter.removeChild(lastChild);
      }

      var circle = gCenter.firstChild;
      circle.setAttribute('style', 'fill: #5f87b732');

      var hadImage = gCenter.getAttribute('image');
      if (hadImage) {
        var img = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'image',
        );
        img.setAttribute('href', backButtonImg);
        img.setAttribute('height', '20');
        img.setAttribute('width', '40');
        img.setAttribute('x', -20);
        img.setAttribute('y', -10);

        gCenter.appendChild(img);
      }
    }
  });

  var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('d', self.createSectorCmds(startAngleDeg, endAngleDeg));
  g.appendChild(path);

  if (item) {
    g.setAttribute('class', 'sector');
    g.setAttribute('data-id', item.id);
    g.setAttribute('data-index', index);
    g.setAttribute('fill', '#5f87b750');
    g.setAttribute('image', item.image);

    if (item.title) {
      var textPosition = this.calculateTextPosition(
        item.title,
        startAngleDeg,
        endAngleDeg,
      );
      var texts = self.createText(textPosition.x, textPosition.y, item.title);
      texts.forEach(text => {
        if (item.icon) {
          text.setAttribute('transform', 'translate(0,8)');
        } else {
          text.setAttribute('transform', 'translate(0,2)');
        }

        g.appendChild(text);
      });
    }

    if (item.icon) {
      var use = self.createUseTag(centerPoint.x, centerPoint.y, item.icon);
      if (item.title) {
        use.setAttribute('transform', 'translate(-5,-8)');
      } else {
        use.setAttribute('transform', 'translate(-5,-5)');
      }

      g.appendChild(use);
    }
  } else {
    g.setAttribute('class', 'dummy');
    g.setAttribute('fill', '#3C506842');
  }

  svg.appendChild(g);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createSectorCmds = function (startAngleDeg, endAngleDeg) {
  var self = this;

  var initPoint = RadialMenu.getDegreePos(startAngleDeg, self.radius);
  var path = 'M' + RadialMenu.pointToString(initPoint);

  var radiusAfterScale = self.radius * (1 / self.scale);
  path +=
    'A' +
    radiusAfterScale +
    ' ' +
    radiusAfterScale +
    ' 0 0 0' +
    RadialMenu.pointToString(RadialMenu.getDegreePos(endAngleDeg, self.radius));
  path +=
    'L' +
    RadialMenu.pointToString(
      RadialMenu.getDegreePos(endAngleDeg, self.innerRadius),
    );

  var radiusDiff = self.radius - self.innerRadius;
  var radiusDelta = (radiusDiff - radiusDiff * self.scale) / 2;
  var innerRadius = (self.innerRadius + radiusDelta) * (1 / self.scale);
  path +=
    'A' +
    innerRadius +
    ' ' +
    innerRadius +
    ' 0 0 1 ' +
    RadialMenu.pointToString(
      RadialMenu.getDegreePos(startAngleDeg, self.innerRadius),
    );
  path += 'Z';

  return path;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createText = function (x, y, title) {
  var self = this;

  var axisY = y - 3;
  var words = title.split(' ');
  var textList = [];

  for (const word of words) {
    var text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.setAttribute('text-anchor', 'middle');
    text.setAttribute('font-size', '3.5px');
    text.setAttribute('x', x);
    text.setAttribute('y', words.length > 1 ? axisY : y);
    text.innerHTML = word;
    axisY += 5;
    textList.push(text);
  }

  return textList;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.createCircle = function (x, y, r) {
  var circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  circle.setAttribute('cx', RadialMenu.numberToString(x));
  circle.setAttribute('cy', RadialMenu.numberToString(y));
  circle.setAttribute('r', r);
  return circle;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.calcScale = function () {
  var self = this;
  var totalSpace = self.sectorSpace * self.sectorCount;
  var circleLength = Math.PI * 2 * self.radius;
  var radiusDelta = self.radius - (circleLength - totalSpace) / (Math.PI * 2);
  return (self.radius - radiusDelta) / self.radius;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.getSectorCenter = function (startAngleDeg, endAngleDeg) {
  var self = this;
  var positions = RadialMenu.getDegreePos(
    (startAngleDeg + endAngleDeg) / 2,
    self.innerRadius + (self.radius - self.innerRadius) / 2,
  );

  return positions;
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.prototype.addIconSymbols = function () {
  var self = this;
  var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('class', 'icons');

  // return
  var returnSymbol = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'symbol',
  );
  returnSymbol.setAttribute('id', 'return');
  returnSymbol.setAttribute('viewBox', '0 0 489.394 489.394');
  var returnPath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  );
  returnPath.setAttribute(
    'd',
    'M375.789,92.867H166.864l17.507-42.795c3.724-9.132,1-19.574-6.691-25.744c-7.701-6.166-18.538-6.508-26.639-0.879' +
      'L9.574,121.71c-6.197,4.304-9.795,11.457-9.563,18.995c0.231,7.533,4.261,14.446,10.71,18.359l147.925,89.823' +
      'c8.417,5.108,19.18,4.093,26.481-2.499c7.312-6.591,9.427-17.312,5.219-26.202l-19.443-41.132h204.886' +
      'c15.119,0,27.418,12.536,27.418,27.654v149.852c0,15.118-12.299,27.19-27.418,27.19h-226.74c-20.226,0-36.623,16.396-36.623,36.622' +
      'v12.942c0,20.228,16.397,36.624,36.623,36.624h226.74c62.642,0,113.604-50.732,113.604-113.379V206.709' +
      'C489.395,144.062,438.431,92.867,375.789,92.867z',
  );
  returnSymbol.appendChild(returnPath);
  svg.appendChild(returnSymbol);

  var closeSymbol = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'symbol',
  );
  closeSymbol.setAttribute('id', 'close');
  closeSymbol.setAttribute('viewBox', '0 0 41.756 41.756');

  var closePath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  );
  closePath.setAttribute(
    'd',
    'M27.948,20.878L40.291,8.536c1.953-1.953,1.953-5.119,0-7.071c-1.951-1.952-5.119-1.952-7.07,0L20.878,13.809L8.535,1.465' +
      'c-1.951-1.952-5.119-1.952-7.07,0c-1.953,1.953-1.953,5.119,0,7.071l12.342,12.342L1.465,33.22c-1.953,1.953-1.953,5.119,0,7.071' +
      'C2.44,41.268,3.721,41.755,5,41.755c1.278,0,2.56-0.487,3.535-1.464l12.343-12.342l12.343,12.343' +
      'c0.976,0.977,2.256,1.464,3.535,1.464s2.56-0.487,3.535-1.464c1.953-1.953,1.953-5.119,0-7.071L27.948,20.878z',
  );
  closeSymbol.appendChild(closePath);
  svg.appendChild(closeSymbol);

  self.holder.appendChild(svg);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.getDegreePos = function (angleDeg, length) {
  return {
    x: Math.sin(RadialMenu.degToRad(angleDeg)) * length,
    y: Math.cos(RadialMenu.degToRad(angleDeg)) * length,
  };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.pointToString = function (point) {
  return (
    RadialMenu.numberToString(point.x) +
    ' ' +
    RadialMenu.numberToString(point.y)
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.numberToString = function (n) {
  if (Number.isInteger(n)) {
    return n.toString();
  } else if (n) {
    var r = (+n).toFixed(5);
    if (r.match(/\./)) {
      r = r.replace(/\.?0+$/, '');
    }
    return r;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.resolveLoopIndex = function (index, length) {
  if (index < 0) {
    index = length + index;
  }
  if (index >= length) {
    index = index - length;
  }
  if (index < length) {
    return index;
  } else {
    return null;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.degToRad = function (deg) {
  return deg * (Math.PI / 180);
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.setClassAndWaitForTransition = function (node, newClass) {
  return new Promise(function (resolve) {
    function handler(event) {
      if (event.target == node && event.propertyName == 'visibility') {
        node.removeEventListener('transitionend', handler);
        resolve();
      }
    }
    node.addEventListener('transitionend', handler);
    node.setAttribute('class', newClass);
  });
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
RadialMenu.nextTick = function (fn) {
  setTimeout(fn, 10);
};

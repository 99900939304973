import * as React from 'react';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { CardsContent, Painel, SideNavigation } from './styles';
import { Company, Dashboard } from '../CompaniesPage';
import api from '../../services/api';
import UserGroupCard from '../../components/cards/usergroupCard';
import { OptionsBar } from '../CompaniesPage/styles';
import Modal from '../../components/modal';
import { useLogger } from '../../context/logContext';
import { Role } from '../AccessControlPage/roleTab';
import { DownloadItem } from '../../components/addUrlDownloads';

interface AlertProps {
  open: boolean;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
}

export interface Widget {
  widgetName: string;
  widgetUrlImage: string;
  description: string;
  widgetUrl: string;
  type: string;
  downloadItems?: DownloadItem[];
  internalWidgetType?: string;
}

export interface WidgetNode extends Widget {
  items: WidgetNode[] | undefined;
}

export interface User {
  username: string;
  email: string;
}

export interface UserGroup {
  userGroupId: number;
  name: string;
  companyId: number;
  dashboards: number[];
  roles: number[];
  users: string[];
  widgets: string[];
  moreInformation: string[];
}

const UserGroupsPage: React.FC = () => {
  const { logger } = useLogger();
  const [companiesLine, setCompaniesLine] = useState<any>();
  const [userGroupsPanel, setUserGroupsPanel] = useState<any>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [open, setOpen] = useState(false);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[]>([]);
  const [inputSearchValue, setInputSearchValue] = useState<string>();
  const [companyFilter, setCompanyFilter] = useState<number>();
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);

  const [isloading, setIsLoading] = useState<boolean>(true);

  const countUser = useCallback(
    (userEmail: string) => {
      let count = 0;
      userGroups.forEach((usergroup: UserGroup) => {
        if (
          usergroup.users
            .map((user: any) => {
              return user.email;
            })
            .includes(userEmail)
        ) {
          count += 1;
        }
      });
      return count;
    },
    [userGroups],
  );

  const handleFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputSearchValue(event.target.value.toLowerCase());
    },
    [],
  );

  const getCompanies = useCallback(() => {
    api
      .get('/companies')
      .then((response: any) => {
        setCompanies(
          response.data.map((company: Company) => {
            return {
              ...company,
            };
          }),
        );
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const getRoles = useCallback(() => {
    api
      .get('/roles')
      .then(response => {
        setRoles(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const getUserGroups = useCallback(() => {
    setIsLoading(true);
    api
      .get('/usergroups/getAll')
      .then((response: any) => {
        setIsLoading(false);
        setUserGroups(response.data?.usergroups);
        setFilteredUserGroups(response.data?.usergroups);

        setDashboards(
          response.data?.dashboardList.map((dash: any) => {
            return {
              dashboardName: dash.dashboard_title,
              dashboardId: dash.id,
            };
          }),
        );
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCompanies();
    getRoles();
    getUserGroups();
  }, [getCompanies, getRoles, getUserGroups]);

  useEffect(() => {
    let filtered = [...userGroups];
    if (companyFilter) {
      filtered = filtered.filter(
        (usergroup: UserGroup) => usergroup.companyId === companyFilter,
      );
    }
    if (inputSearchValue) {
      const inputValeuTam = inputSearchValue.length;
      filtered = filtered.filter((usergroup: UserGroup) => {
        const nFirstCharacters = usergroup.name.slice(0, inputValeuTam);
        return nFirstCharacters.toLowerCase() === inputSearchValue;
      });
    }

    setFilteredUserGroups(filtered);
  }, [companyFilter, inputSearchValue, userGroups]);

  const handleClickOnCompanyLeftSideLine = useCallback((companyId: number) => {
    setCompanyFilter(companyId);
  }, []);

  const handleSaveEdit = useCallback(
    (usergroupToEdit: any) => {
      api
        .put(`/usergroups/${usergroupToEdit.userGroupId}`, usergroupToEdit)
        .then(() => {
          getUserGroups();
        })
        .catch((error: any) => {
          console.log('err', error);
          logger('error', 'Edit UserGroup', `${error.code} - ${error.message}`);
        });
    },
    [getUserGroups, logger],
  );

  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false,
    message: '',
    severity: 'info',
  });
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const uploadFile = async (
    usergroupId: number,
    file: File,
    description: string,
  ): Promise<DownloadItem | any> => {
    // console.log('Uploadeding file...', file);
    try {
      setLoadingMessage(`Uploading file '${file.name}'`);

      const uploadConfig = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const data = new FormData();
      data.append('file', file);
      data.append('description', description);

      const response = await api.post(
        `/files/${usergroupId}`,
        data,
        uploadConfig,
      );

      const urlString = response.request?.responseURL.replace(
        '/files',
        '/uploads',
      );
      const lastSlashIndex = urlString.lastIndexOf('/');
      const newUrlString = urlString.substring(0, lastSlashIndex);

      return {
        fileId: response.data.fileId,
        description,
        url: `${newUrlString}/${response.data.filename ?? ''}`,
      };
    } catch (error: any) {
      console.error(error.response.data.message);
      return {};
    }
  };

  const handleSaveSettings = useCallback(
    (usergroupToEdit: any, fileIdsToDelete: number[]) => {
      setIsLoading(true);
      const usergroupId = usergroupToEdit.userGroupId;

      // Recursive function to traverse the tree and update download items for all WidgetNodes
      const updateWidgetDownloadItems = async (
        widgetNode: WidgetNode,
      ): Promise<WidgetNode> => {
        // Update the current widget's downloadItems
        const newDownloadItems = widgetNode.downloadItems
          ? await Promise.all(
              widgetNode.downloadItems.map(async (item: DownloadItem) => {
                if (!item.fileToBeUploaded) {
                  return item; // Return item unchanged if no file to be uploaded
                }

                // console.log('found file to be uploaded', item.fileToBeUploaded);

                // Upload the file and return the updated item
                return uploadFile(
                  usergroupId,
                  item.fileToBeUploaded,
                  item.description,
                );
              }),
            )
          : [];

        // Recursively process the children (items) of the widgetNode
        const updatedWidgetNode = {
          ...widgetNode,
          downloadItems: newDownloadItems,
          items: widgetNode.items
            ? await Promise.all(
                widgetNode.items.map(childNode =>
                  updateWidgetDownloadItems(childNode),
                ),
              )
            : undefined, // Keep items as undefined if no children
        };

        return updatedWidgetNode;
      };

      // returns usergroupToEdit object with new files uploaded
      const handleFilesUpload = async (): Promise<any> => {
        try {
          if (!usergroupToEdit?.settings?.widgets) {
            console.warn('No widgets to upload');
            return usergroupToEdit;
          }
          setLoadingMessage('Uploading files...');

          // Loop through the top-level widgets and apply the recursive function
          const newWidgets = await Promise.all(
            usergroupToEdit.settings.widgets.map(async (widget: WidgetNode) => {
              return updateWidgetDownloadItems(widget);
            }),
          );

          const newUsergroupToEdit = usergroupToEdit;
          newUsergroupToEdit.settings.widgets = newWidgets;

          setAlertProps({
            open: true,
            message: 'Settings saved successfully',
            severity: 'success',
          });

          return newUsergroupToEdit;
        } catch (error) {
          console.error('Error uploading files', error);
          throw error;
        }
      };

      // First upload files, if any
      handleFilesUpload()
        .then(usergroupToEditWithNewFiles => {
          api
            .put(`/usergroups/${usergroupId}`, usergroupToEditWithNewFiles)
            .then(() => {
              try {
                if (fileIdsToDelete.length > 0) {
                  const queryString = fileIdsToDelete
                    .map(id => `fileIdsToDelete=${id}`)
                    .join('&');
                  api.delete(`/files/deleteByIds?${queryString}`);
                }
              } catch (error: any) {
                console.error(
                  'Error deleting files',
                  error.response.data.message,
                );
              }

              getUserGroups();
            })
            .catch((err: any) => {
              console.log(err);
            });
        })
        .finally(() => {
          setIsLoading(false);
          setLoadingMessage('');
        });
    },
    [getUserGroups, setIsLoading],
  );

  const handleDelete = useCallback(
    (obj: any) => {
      api
        .delete(`/usergroups/${obj.userGroup.userGroupId}`)
        .then(() => {
          getUserGroups();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'Delete usergroups',
            `${error.code} - ${error.message}`,
          );
        });
      obj.handleCloseOptions();
    },
    [getUserGroups, logger],
  );

  const handleAdd = useCallback(
    (newUserGroup: UserGroup) => {
      api
        .post('/usergroups', newUserGroup)
        .then(() => {
          getUserGroups();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'create new usergroup',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getUserGroups, logger],
  );

  useEffect(() => {
    setCompaniesLine(
      companies.map((company: Company, index: number) => {
        return (
          <div key={index}>
            <div style={{ height: '25px' }}></div>
            <Button
              key={index}
              style={{
                left: '24px',
                height: '60px',
                borderBottomRightRadius: '50px',
                borderTopRightRadius: '50px',
              }}
              variant="contained"
              onClick={() =>
                handleClickOnCompanyLeftSideLine(company.companyId)
              }
            >
              {company.name}
            </Button>
          </div>
        );
      }),
    );
    setUserGroupsPanel(
      filteredUserGroups.map((userGroup: UserGroup, index: number) => {
        return (
          <div style={{ marginTop: '30px', height: '25%' }} key={index}>
            <UserGroupCard
              key={index}
              userGroup={userGroup}
              handleDelete={handleDelete}
              handleSaveEdit={handleSaveEdit}
              handleSaveSettings={handleSaveSettings}
              dashboardListForm={dashboards}
              rolesListForm={roles}
              companyListForm={companies}
              countUser={countUser}
            ></UserGroupCard>
          </div>
        );
      }),
    );
  }, [
    companies,
    countUser,
    dashboards,
    filteredUserGroups,
    handleClickOnCompanyLeftSideLine,
    handleDelete,
    handleSaveEdit,
    handleSaveSettings,
    roles,
  ]);

  const handleSubmit = (): void => {
    setOpen(true);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertProps({
      open: false,
      message: '',
      severity: 'info',
    });
  };

  return (
    <>
      <Painel id="panel">
        <OptionsBar id="options">
          <div id="title">
            <h1 style={{ color: 'white' }}>User groups</h1>
          </div>

          <div
            id="searchBar"
            style={{
              width: '50%',
              alignSelf: 'center',
              paddingTop: '10px',
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search..."
              onChange={handleFilter}
              variant="outlined"
              style={{ width: '80%' }}
              // sx={{
              //   // Root class for the input field
              //   '& .MuiOutlinedInput-root': {
              //     color: '#F2F0EF',
              //     fontFamily: 'Arial',
              //     fontWeight: 'bold',
              //     // Class for the border around the input field
              //     '& .MuiOutlinedInput-notchedOutline': {
              //       borderColor: '#F2F0EF',
              //       borderWidth: '2px',
              //     },
              //   },
              //   // Class for the label of the input field
              //   '& .MuiInputLabel-outlined': {
              //     color: '#F2F0EF',
              //     fontWeight: 'bold',
              //   },
              // }}
            />
          </div>

          <div
            id="buttonAdd"
            style={{ alignSelf: 'center', paddingTop: '10px' }}
          >
            <Fab color="primary" aria-label="add" onClick={handleSubmit}>
              <AddIcon />
            </Fab>
          </div>
        </OptionsBar>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            paddingTop: '35px',
          }}
        >
          {isloading === false && (
            <>
              <SideNavigation id="navigation">{companiesLine}</SideNavigation>
              <CardsContent id="cards">{userGroupsPanel}</CardsContent>
            </>
          )}
        </div>
      </Painel>
      <Modal
        content={'usergroups'}
        userGroupProp={{
          open,
          setOpen,
          dashboardListForm: dashboards,
          companyListForm: companies,
          rolesListForm: roles,
          handleAdd,
          getUserGroups,
        }}
      ></Modal>

      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isloading}
      >
        <CircularProgress color="inherit" />
        <span style={{ marginLeft: '12px' }}>{loadingMessage}</span>
      </Backdrop>

      <Snackbar
        open={alertProps.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertProps.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alertProps.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserGroupsPage;

import React, { useCallback, useEffect, useState, JSX } from 'react';
import { Select } from 'antd';
import styled, { css, keyframes } from 'styled-components';
import { useAuth } from '../../context/authContext';
import { HeaderBoxCenter } from './styles';

interface FilterUsergroupProps {
  children?: JSX.Element;
  shouldHighlightUserGroups?: boolean;
  onChangeUsergroup?: () => void;
}

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); }
  100% { transform: scale(1); }
`;

const StyledSelect = styled(Select)<{ isHighlighted: boolean }>`
  width: 25%;
  height: 100%;
  transition: all 0.3s ease-in-out;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      animation: ${pulse} 1s ease-in-out infinite;
    `}
`;

const FilterUsergroup: React.FC<FilterUsergroupProps> = ({
  children,
  shouldHighlightUserGroups,
  onChangeUsergroup,
}) => {
  const authContext = useAuth();
  const { user } = authContext;

  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [isHighlighted, setIsHighlighted] = useState<boolean>(
    shouldHighlightUserGroups ?? false,
  );

  useEffect(() => {
    setTimeout(() => setIsHighlighted(false), 3000);
  }, []);

  useEffect(() => {
    setSelectedGroup(user.selectedUsergroup?.name ?? '');
  }, [user.selectedUsergroup?.name]);

  const handleGroupChange = useCallback(
    async (e: any): Promise<void> => {
      const value = e;
      setSelectedGroup(value);

      authContext.updateSelectedUsergroup(
        user.usergroups.find(
          (usergroup: any) => usergroup.userGroupId === parseInt(value, 10),
        ),
      );

      if (shouldHighlightUserGroups) {
        setIsHighlighted(true);
        setTimeout(() => setIsHighlighted(false), 3000);
      }
      if (onChangeUsergroup) {
        onChangeUsergroup();
      }
    },
    [
      authContext,
      onChangeUsergroup,
      shouldHighlightUserGroups,
      user.usergroups,
    ],
  );

  return (
    <HeaderBoxCenter
      style={{ justifyContent: children ? 'space-between' : 'center' }}
    >
      <StyledSelect
        isHighlighted={isHighlighted}
        value={selectedGroup}
        onChange={handleGroupChange}
        optionLabelProp="label"
      >
        <option value="" disabled>
          Bitte wähle hier das gewünschte Projekt aus.
        </option>

        {user.usergroups.map((usergroup: any) => (
          <option key={usergroup.userGroupId} value={usergroup.userGroupId}>
            {usergroup.name}
          </option>
        ))}
      </StyledSelect>
      {children}
    </HeaderBoxCenter>
  );
};

export default FilterUsergroup;

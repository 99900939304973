import React, { useCallback, useEffect } from 'react';
import { RadialMenu } from './radialMenu.js';
import { Widget } from '../../pages/UserGroupsPage';

interface props {
  widgets: Widget[];
  setSelectedWidget: any;
}

const RadialMenuReact: React.FC<props> = ({ widgets, setSelectedWidget }) => {
  const findWidget = useCallback((widgetsList: any[], widName: string): any => {
    return widgetsList.reduce((selectedWidget, widget) => {
      if (selectedWidget) return selectedWidget;

      if (widget.widgetName === widName) return widget;

      if (widget.items && widget.items.length > 0) {
        return findWidget(widget.items, widName);
      }

      return null;
    }, null);
  }, []);

  const handleClick = useCallback(
    (event: any) => {
      const clickedSector = event.title;
      const selectedWidget = findWidget(widgets, clickedSector);
      setSelectedWidget(selectedWidget);
    },
    [findWidget, setSelectedWidget, widgets],
  );

  const handleDoubleClick = useCallback(
    (event: any) => {
      const clickedSector = event.title;
      const widget = findWidget(widgets, clickedSector);
      if (widget && widget.widgetUrl) {
        window.open(widget.widgetUrl);
      }
    },
    [findWidget, widgets],
  );

  const handleClose = useCallback(() => {
    setSelectedWidget(undefined);
  }, [setSelectedWidget]);

  const deepSearchMappingWidgets = useCallback((widgetList: any[]) => {
    return widgetList.map(widget => {
      const newWidget: any = {
        id: widget.widgetName,
        title: widget.widgetName,
        image: widget.widgetUrlImage,
      };

      if (widget.items && widget.items.length > 0) {
        newWidget.items = deepSearchMappingWidgets(widget.items);
      }

      return newWidget;
    });
  }, []);

  useEffect(() => {
    const menuItems = deepSearchMappingWidgets(widgets);

    if (widgets.length > 0) {
      const radialMenu = document.getElementById('radialMenu');

      if (radialMenu) {
        radialMenu.innerHTML = '';
        const svgMenu = new RadialMenu({
          parent: radialMenu,
          size: 800,
          closeOnClick: true,
          menuItems,
          onClick: handleClick,
          handleDoubleClick,
          onClose: handleClose,
        });
        svgMenu.open();
      }
    }
  }, [
    deepSearchMappingWidgets,
    handleClick,
    handleClose,
    handleDoubleClick,
    widgets,
  ]);

  return <div id="radialMenu" style={{ width: '50%' }}></div>;
};

export default RadialMenuReact;

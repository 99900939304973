import React, { useEffect, useState } from 'react';
import { Container, ListItem } from './styles';

interface Props {
  color: string;
  name: string;
  data: any;
  setTaskToEdit: any;
}

const SubQuadrant: React.FC<Props> = ({ color, name, data, setTaskToEdit }) => {
  const [fontSize, setFontSize] = useState<number>(0.83);

  useEffect(() => {
    if (data.length > 6) {
      const decrease = (data.length - 6) * 0.1;
      const newfontSize = 0.83 - decrease;
      setFontSize(newfontSize);
    }
  }, [data.length]);

  const handleClick = (event: any, task: any): void => {
    event.stopPropagation();
    setTaskToEdit(task);
  };

  return (
    <>
      <Container color={color} name={name}>
        <ul
          style={{ margin: 0, paddingInlineStart: '1vw', placeItems: 'start' }}
        >
          {data?.map((task: any, index: number) => (
            <ListItem
              key={index}
              fontSize={fontSize}
              title={task.goal}
              onClick={(event: any) => handleClick(event, task)}
            >
              {task.shortTitle}
            </ListItem>
          ))}
        </ul>
      </Container>
    </>
  );
};

export default SubQuadrant;

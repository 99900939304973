import React, { useEffect, useState } from 'react';
import { useAuth, User } from '../../context/authContext';
import { Widget } from '../UserGroupsPage';
import { Cards, HeaderWilkommen, RadialContent } from './styles';
import HomePageCard from '../../components/cards/homePageCard';
import RadialMenuReact from '../../components/radialMenu';
import FilterUsergroup from '../../components/FilterUsergroup';
import OverlayUsergroupRequired from '../../components/OverlayUsergroupRequired';
import Loading from '../../components/loading';

interface Props {
  widgets?: Widget[];
}

const InitialPage: React.FC<Props> = ({ widgets = undefined }) => {
  const [userLocal, setUserLocal] = useState<User>();
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const [widgetsList, setWidgetsList] = useState<Widget[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isDropdownInHeader, setIsDropdownInHeader] = useState<boolean>(true);
  const authContext = useAuth();
  const { user } = authContext;

  useEffect(() => {
    if (user.usergroups.length === 1) {
      setIsDropdownInHeader(false);
    }
    if (widgets) {
      // PREVIW FROM USERGROUPS
      authContext.getUserGroups(user).then(userFromDatabase => {
        setWidgetsList(widgets ?? []);
        setUserLocal(userFromDatabase);
      });
    } else {
      // HOME PAGE
      setIsLoading(true);
      authContext
        .getUserGroups(user)
        .then(userFromDatabase => {
          setWidgetsList(
            user.selectedUsergroup
              ? user.selectedUsergroup.settings?.widgets
              : [],
          );
          setUserLocal(userFromDatabase);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [authContext, user, widgets]);

  return (
    <>
      {user.selectedUsergroup ? (
        <>
          {isDropdownInHeader && (
            <FilterUsergroup shouldHighlightUserGroups={true} />
          )}

          {userLocal && (
            <HeaderWilkommen>
              <span>{`Willkommen ${userLocal?.name}!`}</span>
            </HeaderWilkommen>
          )}
          {isLoading ? (
            <Loading></Loading>
          ) : (
            <RadialContent key={user?.selectedUsergroup?.userGroupId}>
              <RadialMenuReact
                setSelectedWidget={setSelectedWidget}
                widgets={widgetsList}
              ></RadialMenuReact>
              {selectedWidget && (
                <Cards id="cards">
                  <HomePageCard widget={selectedWidget}></HomePageCard>
                </Cards>
              )}
            </RadialContent>
          )}
        </>
      ) : (
        <>
          {userLocal && (
            <HeaderWilkommen>
              <span>{`Willkommen ${userLocal?.name}!`}</span>
            </HeaderWilkommen>
          )}

          <OverlayUsergroupRequired></OverlayUsergroupRequired>
        </>
      )}
    </>
  );
};

export default InitialPage;

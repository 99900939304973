import styled from 'styled-components';

export const HeaderBoxCenter = styled('div')(() => ({
  borderBottom: 'solid 1px #F5F5F5',
  background: '#F8F8F8',
  display: 'flex',
  height: '30px',
  padding: '0.3rem',
  zIndex: 1100,
}));

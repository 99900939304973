import * as React from 'react';
import ReactDOM from 'react-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Events,
  STATUS,
  Step,
} from 'react-joyride';
import { Container, HelpButton } from './styles';
import TaskListTab from './taskListTab';
import EisenhowerTab from './eisenhowerTab';
import api from '../../services/api';
import { GuestUser } from '../../components/guestsBox';
import { JoyrideContext } from './joyride.context';
import { tutorialMobileSteps } from './tutorialMobileSteps';
import { useAuth } from '../../context/authContext';
import FilterUsergroup from '../../components/FilterUsergroup';
import OverlayUsergroupRequired from '../../components/OverlayUsergroupRequired';

export interface Guest {
  name: string;
  email: string;
}

export type Status = 'Nicht Begonnen' | 'Laufend' | 'Erledigt';

const drawerWidth = 150;

const portalstyles: any = {
  height: '100vh',
  width: '150px',
  backgroundColor: 'rgb(223, 223, 223)',
  position: 'fixed',
  display: { xs: 'none', lg: 'block' },
};

export interface Task {
  id: any;
  _id?: string;
  taskId: any;
  usergroupIds: number[];
  shortTitle: string;
  description: string;
  effort: string;
  impact: string;
  assign: Guest;
  due: Date;
  goal: string;
  status: Status;
  guests: GuestUser[];
  companyId: number;
}
interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const joyrideStyles = {
  options: {
    zIndex: 10001,
  },
};

const baseInitialSteps: Step[] = [
  {
    target: 'body',
    title: 'Willkommen!',
    content:
      'Beginnen wir mit einer Übersicht und Erläuterung der To-Do-Liste.',
    disableBeacon: true,
    styles: joyrideStyles,
  },
  {
    target: '#create-task-button',
    title: 'Erstellen Sie eine neue Aufgabe.',
    content: (
      <div>
        Wählen Sie das folgende Symbol{' '}
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '15px',
            height: '15px',
            padding: '2px',
            borderRadius: '50%',
            fontSize: '11px',
            color: 'white',
            backgroundColor: 'rgb(25, 118, 210)',
          }}
        >
          +
        </span>
        , um eine neue Aufgabe zu erstellen.
      </div>
    ),

    placement: 'left-end' as const,
    styles: joyrideStyles,
  },
  {
    target: '#header',
    title: 'Neue Aufgabe.',
    content:
      'Erstellen Sie eine neue Aufgabe, indem Sie die folgenden Informationen eingeben.',
    placement: 'left-start' as const,
    styles: joyrideStyles,
  },
  {
    target: '#body-form',
    title: 'Details zur Aufgabe.',
    content:
      'Wählen Sie zudem die Organisationseinheit und die verantwortlichen Personen aus. Außerdem können Sie den Aufwand, den Impact und den aktuellen Status bestimmen.',
    placement: 'left' as const,
    styles: joyrideStyles,
  },
  {
    target: '#guest-container',
    title: 'Fügen Sie Gäste zu einer Aufgabe hinzu.',
    content:
      'Um eine neue Person zur Aufgabe hinzuzufügen, wählen Sie unter Gäste das Symbol (+).',
    placement: 'left' as const,
    styles: joyrideStyles,
  },
  {
    target: '.MuiDataGrid-topContainer',
    title: 'Übersicht über alle Aufgaben.',
    content: (
      <span>
        Alle hinzugefügten Aufgaben werden hier aufgelistet und können über die
        entsprechenden Symbole gefiltert (
        <svg
          style={{ verticalAlign: 'middle', color: 'grey', width: '15px' }}
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1vooibu-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="TripleDotsVerticalIcon"
        >
          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
        </svg>
        ) und sortiert (
        <svg
          style={{ verticalAlign: 'middle', color: 'grey', width: '15px' }}
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-ptiqhd-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ArrowUpwardIcon"
        >
          <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path>
        </svg>
        ) werden.
      </span>
    ),
    placement: 'bottom-end' as const,
    // styles: joyrideStyles,
  },
];

const baseFinalSteps: Step[] = [
  {
    target: '#do-it-quadrant',
    title: 'Entscheidungsmatrix',
    content:
      'Klicken Sie auf die Felder der Entscheidungsmatrix, um die Aufgaben aus der To-Do-Liste zu sehen.',
    placement: 'right-end' as const,
    styles: joyrideStyles,
  },
];

const largeSpecificSteps: Step[] = [
  {
    target: '#decision-matrix-menu-item-large',
    title: 'Wechsel der Übersicht.',
    content:
      "Im linken Menüfeld können Sie zwischen den To-Do's und der Entscheidungsmatrix wechseln.",
    placement: 'right-start' as const,
    styles: joyrideStyles,
  },
];

const largeSteps = [
  ...baseInitialSteps,
  ...largeSpecificSteps,
  ...baseFinalSteps,
  {
    target: '#help-button-large',
    title: 'Tutorial beenden.',
    content: (
      <span>
        Sie können nun neue Aufgaben hinzufügen. Wenn Sie weitere Hilfe
        benötigen, können Sie die Schritte auf dem Hilfesymbol{' '}
        <span
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '15px',
            height: '15px',
            padding: '2px',
            borderRadius: '50%',
            fontSize: '11px',
            color: 'white',
            backgroundColor: 'rgb(25, 118, 210)',
          }}
        >
          ?
        </span>{' '}
        erneut aufrufen.
      </span>
    ),
    placement: 'right-start' as const,
    styles: joyrideStyles,
  },
];

const mobileSteps: Step[] = tutorialMobileSteps;

const TodoPage: React.FC = () => {
  const updateShowTaskTutorial = (show: boolean): void => {
    const storageKey = '@Victor-insights:user';

    const storedData = localStorage.getItem(storageKey);
    if (!storedData) {
      console.warn('LocalStorage is empty.');
      return;
    }

    try {
      const userData = JSON.parse(storedData);
      userData.show_task_tutorial = show;
      localStorage.setItem(storageKey, JSON.stringify(userData));
    } catch (error) {
      console.error('Something wrong with Local storage', error);
    }
  };

  const isDeviceLgSize = window.matchMedia('(min-width: 1200px)').matches;
  const initialSteps = isDeviceLgSize ? largeSteps : mobileSteps;

  const [{ run, stepIndex, steps }, setState] = useState<JoyrideState>({
    run: true,
    stepIndex: 0,
    steps: initialSteps,
  });

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleDrawerClose = (): void => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const [chosenTab, setChosenTab] = useState<number>(0);
  const handleHelpButton = (): void => {
    setChosenTab(0);
    handleDrawerClose();
    updateShowTaskTutorial(true);
    setState({ run: true, stepIndex: 0, steps: initialSteps });
  };

  const useDrawer = (
    decisionMatrixMenuId: string,
    todoMenuItemRef: React.RefObject<HTMLDivElement>,
    decisionMatrixMenuItemref: React.RefObject<HTMLDivElement>,
    helpButtonId: string,
  ): React.JSX.Element => {
    return React.useMemo(() => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100%',
          }}
        >
          <div>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  ref={todoMenuItemRef}
                  onClick={(): void => setChosenTab(0)}
                >
                  <ListItemText primary={'To-Do´s'} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  ref={decisionMatrixMenuItemref}
                  id={decisionMatrixMenuId}
                  onClick={(): void => {
                    setChosenTab(1);
                  }}
                >
                  <ListItemText primary={'Entscheidungs-Matrix'} />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
          {/* Button at the bottom */}

          <HelpButton id={helpButtonId} onClick={handleHelpButton}>
            ?
          </HelpButton>
          {/* <button>Help</button> */}
        </div>
      );
    }, [
      decisionMatrixMenuId,
      decisionMatrixMenuItemref,
      helpButtonId,
      todoMenuItemRef,
    ]);
  };

  const todoMenuItemRefMobile = useRef<HTMLDivElement>(null);
  const todoMenuItemRefLarge = useRef<HTMLDivElement>(null);

  const decisionMatrixMenuItemrefMobile = useRef<HTMLDivElement>(null);
  const decisionMatrixMenuItemrefLarge = useRef<HTMLDivElement>(null);

  const drawerMobile = useDrawer(
    'decision-matrix-menu-item-mobile',
    todoMenuItemRefMobile,
    decisionMatrixMenuItemrefMobile,
    'help-button-mobile',
  );
  const drawerLarge = useDrawer(
    'decision-matrix-menu-item-large',
    todoMenuItemRefLarge,
    decisionMatrixMenuItemrefLarge,
    'help-button-large',
  );

  // Reference to elements that will be used in the tutorial, in the joyrideContext
  const addNewTaskButtonRef = useRef<HTMLButtonElement>(null);
  const closeNewTaskDrawerButtonRef = useRef<HTMLButtonElement>(null);
  const openTabsDrawerButtonRef = useRef<HTMLButtonElement>(null);

  const getShowTaskTutorialByLocalStorage = useCallback((): void => {
    const storageKey = '@Victor-insights:user';

    const storedData = localStorage.getItem(storageKey);
    if (!storedData) {
      console.warn('LocalStorage is empty.');
      return;
    }

    try {
      const userData = JSON.parse(storedData);
      if (userData.show_task_tutorial === false) {
        setState({ run: false, stepIndex: 0, steps: initialSteps });
      }
    } catch (error) {
      console.error('Erro ao processar os dados do localStorage:', error);
    }
  }, [initialSteps]);

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { action, index, status, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false, stepIndex: 0, steps });

      updateShowTaskTutorial(false);

      api.put('/users/finishTasksTutorial').catch((error: any) => {
        console.error(error.response.data.message);
      });
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      const isMobile = !isDeviceLgSize;

      let timeoutToNextStep = 0;

      const stepsOnTodoTab = [0, 1, 5];
      if (
        stepsOnTodoTab.includes(nextStepIndex) &&
        !stepsOnTodoTab.includes(index)
      ) {
        // Next step is on Todo but current step is not
        todoMenuItemRefLarge.current?.click();
        todoMenuItemRefMobile.current?.click();
        timeoutToNextStep = 400;
      }

      if (
        (nextStepIndex === 2 && index === 1) ||
        (nextStepIndex === 4 && index === 5)
      ) {
        // Open the "Add new task" drawer
        addNewTaskButtonRef.current?.click();
        timeoutToNextStep = 400;
      } else if (
        (nextStepIndex === 5 && index === 4) ||
        (nextStepIndex === 1 && index === 2)
      ) {
        // Close the "Add new task" drawer
        closeNewTaskDrawerButtonRef.current?.click();
        timeoutToNextStep = 400;
      } else if (
        (!isMobile &&
          ((nextStepIndex === 7 && index === 6) ||
            (nextStepIndex === 7 && index === 8))) ||
        (isMobile &&
          ((nextStepIndex === 8 && index === 7) ||
            (nextStepIndex === 8 && index === 9)))
      ) {
        // Open the Decision Matrix tab
        decisionMatrixMenuItemrefLarge.current?.click();
        decisionMatrixMenuItemrefMobile.current?.click();
        timeoutToNextStep = 400;
      } else if (
        isMobile &&
        ((nextStepIndex === 7 && index === 6) ||
          (nextStepIndex === 7 && index === 8))
      ) {
        // Open tabs drawer
        openTabsDrawerButtonRef.current?.click();
        timeoutToNextStep = 400;
      } else if (isMobile && nextStepIndex === 6 && index === 7) {
        // Close tabs drawer
        handleDrawerClose();
        timeoutToNextStep = 400;
      }

      // Update state to advance the tour
      if (timeoutToNextStep) {
        setTimeout(() => {
          setState({
            stepIndex: nextStepIndex,
            steps,
            run,
          });
        }, timeoutToNextStep);
      } else {
        setState({
          stepIndex: nextStepIndex,
          steps,
          run,
        });
      }
    }
  };

  const [tasks, setTasks] = useState<Task[]>([]);
  const [tasksByUsergroup, setTasksByUsergroup] = useState<Task[]>([]);
  const { user } = useAuth();

  const getAllTasks = useCallback(() => {
    api
      .post('/tasks/getAll', {
        usergroupIds: user.usergroups.map((ug: any) => ug.userGroupId),
      })
      .then(response => {
        if (response.status === 200) {
          setTasks(response.data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [user.usergroups]);

  useEffect(() => {
    if (user.selectedUsergroup === undefined) return;
    setTasksByUsergroup(
      tasks.filter((task: any) =>
        task.usergroupIds.includes(user.selectedUsergroup.userGroupId),
      ),
    );
  }, [tasks, user.selectedUsergroup]);

  useEffect(() => {
    getShowTaskTutorialByLocalStorage();
  }, [getShowTaskTutorialByLocalStorage]);

  /// GETTASK
  useEffect(() => {
    getAllTasks();
  }, [getAllTasks]);

  const handleDrawerTransitionEnd = (): void => {
    setIsClosing(false);
  };

  const handleDrawerToggle = (): void => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const MuiDrawerPaperStyles = {
    backgroundColor: 'rgb(223, 223, 223)',
    boxSizing: 'border-box',
    width: drawerWidth,
    color: 'rgba(0, 0, 0, 0.6)',
    '& li:nth-of-type(1)': {
      color: chosenTab === 0 ? 'rgb(25, 118, 210)' : 'inherit',
      borderRight: chosenTab === 0 ? '3px solid rgb(25, 118, 210)' : 'none',
    },
    '& li:nth-of-type(2)': {
      color: chosenTab === 1 ? 'rgb(25, 118, 210)' : 'inherit',
      borderRight: chosenTab === 1 ? '3px solid rgb(25, 118, 210)' : 'none',
    },
  };

  const tabStyles = {
    width: {
      xs: '100%',
      lg: `calc(100% - ${drawerWidth}px)`,
    },
    left: { xs: `0`, lg: `${drawerWidth}px` },
    position: 'relative',
  };

  const [backgroundFillerDiv, setBackgroundFillerDiv] =
    React.useState<React.ReactPortal | null>(null);
  useEffect(() => {
    // Check for the target element and update state when available
    const checkElement = (): void => {
      const element = document.getElementById('portal-before-root');
      if (element) {
        setBackgroundFillerDiv(
          ReactDOM.createPortal(
            <Box id="backgroundFillerDiv" sx={portalstyles} />,
            element,
          ),
        );
      } else {
        setTimeout(checkElement, 100);
      }
    };
    checkElement();
  }, []);

  return (
    <>
      {!user.selectedUsergroup ? (
        <OverlayUsergroupRequired></OverlayUsergroupRequired>
      ) : (
        <>
          {user.usergroups.length > 1 && <FilterUsergroup />}
          <Container id="container">
            {backgroundFillerDiv}
            <IconButton
              ref={openTabsDrawerButtonRef}
              id="drawer-toggle-button"
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { lg: 'none' },
                position: 'fixed',
                top: '100px',
                left: '8px',
                zIndex: '1',
                backgroundColor: 'rgb(25, 118, 210)',
                color: 'white',
                borderRadius: '0 20% 20% 0',
                lineHeight: '20px',
              }}
            >
              {'>'}
            </IconButton>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  ...MuiDrawerPaperStyles,
                },
              }}
            >
              {drawerMobile}
            </Drawer>

            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', lg: 'block' },
                '& .MuiDrawer-paper': {
                  ...MuiDrawerPaperStyles,
                  top: '65px',
                  height: 'calc(100vh - 63px)',
                },
              }}
              open
            >
              {drawerLarge}
            </Drawer>

            <Box sx={tabStyles} id="todo-list">
              <JoyrideContext.Provider
                value={{ addNewTaskButtonRef, closeNewTaskDrawerButtonRef }}
              >
                <TaskListTab
                  taskList={tasksByUsergroup}
                  setTaskList={setTasks}
                  chosenTab={chosenTab}
                ></TaskListTab>
              </JoyrideContext.Provider>
            </Box>
            <Box id="decision-matrix">
              <EisenhowerTab
                taskList={tasksByUsergroup}
                setTaskList={setTasks}
                chosenTab={chosenTab}
              ></EisenhowerTab>
            </Box>
            <Joyride
              callback={handleJoyrideCallback}
              continuous
              locale={{
                nextLabelWithProgress: 'Weiter ({step} von {steps})',
                back: 'Zurück',
                skip: 'Überspringen',
                last: 'Schließen',
              }}
              run={run}
              scrollToFirstStep
              showProgress
              showSkipButton
              stepIndex={stepIndex}
              steps={steps}
              // debug={true}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default TodoPage;

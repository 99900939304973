import React, { useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from '@mui/material';
import api from '../../services/api';

const ForgotPasswordPage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState<boolean>(false);

  const checkPassword = useCallback(async (email: string) => {
    try {
      const response = await api.post('/users/checkPassword', {
        email,
        isReset: true,
      });
      return response.data;
    } catch (error: any) {
      console.error(error.response.data.message);
      return {};
    }
  }, []);

  const validateEmail = (email: string): boolean => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return false; // is not valid
    }
    return true; // is valid
  };

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data?.get('email')?.toString().toLowerCase() ?? '';

      const isValid = validateEmail(email);
      if (isValid) {
        // talvez seja necessario enviar mais um parametro para o backend saber quando é reset de senha ou quando é primeiro login
        // outra opçao é criar um fluxo somente para o reset senha
        const hasPassword = await checkPassword(email);
        if (sentEmail) {
          setMessage(
            'Wir senden Ihnen erneut einen neuen Link. Falls er nicht funktioniert, überprüfen Sie bitte, ob Sie Ihre E-Mail-Adresse korrekt eingegeben haben, oder kontaktieren Sie uns.',
          );
        } else {
          setMessage(
            hasPassword.status
              ? 'Das System hat ein Problem bei der Zurücksetzung des Passworts festgestellt. Bitte kontaktieren Sie uns.'
              : hasPassword.message,
          );
        }

        setEmailError(false);
        setSentEmail(true);
      } else {
        setEmailError(true);
      }
    },
    [checkPassword, sentEmail],
  );

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Paper
          elevation={10}
          sx={{ m: 1, width: '45%', height: '60%', borderRadius: '15px' }}
        >
          <div
            style={{
              margin: '15px',
              height: '94%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ height: '120px' }}>
              <h2 style={{ margin: 0 }}>Passwort vergessen</h2>
              <hr
                style={{
                  borderWidth: '0px',
                  borderStyle: 'solid',
                  borderColor: '#E5EAF2',
                  borderBottomWidth: 'thin',
                  marginTop: '12px',
                  marginBottom: '15px',
                }}
                className="MuiDivider-root MuiDivider-fullWidth css-1vdax5c"
              ></hr>
              <span>
                Haben Sie Ihr Passwort vergessen? Bitte geben Sie Ihre
                E-Mail-Adresse ein. Sie erhalten dann per E-Mail einen Link zur
                Erstellung eines neuen Passworts.
              </span>
            </div>

            <div style={{ height: '240px' }}>
              <TextField
                style={{ width: '100%' }}
                id="standard-basic"
                label="E-Mail Adresse"
                name="email"
                variant="standard"
                error={emailError}
                helperText={emailError ? 'Invalid email format' : ''}
              />
              <Button
                type="submit"
                style={{ marginTop: '20px' }}
                variant="outlined"
                disabled={sentEmail}
              >
                Passwort zurücksetzen
              </Button>
              {sentEmail && (
                <Button
                  type="submit"
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  variant="outlined"
                >
                  erneut versuchen
                </Button>
              )}

              {message !== '' && (
                <h4 style={{ color: '#4D50FF', fontWeight: 600 }}>{message}</h4>
              )}
            </div>

            <div style={{ height: '50px' }}>
              <hr
                style={{
                  borderWidth: '0px',
                  borderStyle: 'solid',
                  borderColor: '#E5EAF2',
                  borderBottomWidth: 'thin',
                  marginTop: '12px',
                  marginBottom: '5px',
                }}
                className="MuiDivider-root MuiDivider-fullWidth css-1vdax5c"
              ></hr>
              <Link href="/login" variant="body2">
                Passwort wieder eingefallen?
              </Link>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default ForgotPasswordPage;

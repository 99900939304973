import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import EnumPermissions from '../../utils/Enums/EnumPermissons';

interface LayerProps {
  children: any;
  permissionPage: string;
}

const AccessLayer: React.FC<LayerProps> = ({ children, permissionPage }) => {
  const { user, getUserGroups } = useAuth();
  const [accessAllowed, setAccessAllowed] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    const refreshUserData = async (): Promise<void> => {
      try {
        const userInfo = await getUserGroups(user);
        const userPaths = userInfo.permissions?.map((userPermission: any) =>
          userPermission.name.toLowerCase(),
        );

        if (userPaths && userPaths.includes(permissionPage.toLowerCase())) {
          if (mounted) {
            setAccessAllowed(true);
          }
        }
      } catch (err: any) {
        console.log(err);
        setAccessAllowed(false);
      } finally {
        setLoading(false);
      }
    };

    if (permissionPage !== EnumPermissions.NO_ROLE) {
      refreshUserData();
    } else {
      setAccessAllowed(true);
    }

    return () => {
      mounted = false;
    };
  }, [getUserGroups, permissionPage, user]);

  if (loading) {
    return null;
  }

  if (!accessAllowed) {
    return <Navigate to={'/'} />;
  }

  return <>{children}</>;
};

export default AccessLayer;

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import WrapperRoute from './routes/WrapperRoute';
import DashboardPage from './pages/DashboardPage';
import { AuthProvider } from './context/authContext';
import SignIn from './pages/SignIn';
import CompaniesPage from './pages/CompaniesPage';
import UserGroupsPage from './pages/UserGroupsPage';
import ResetPasswordPage from './pages/SignIn/resetPassword';
import ForgotPasswordPage from './pages/SignIn/forgotPassword';
import Users from './pages/Users';
import { LoggerProvider } from './context/logContext';
import LogsPage from './pages/logsPage';
import InitialPage from './pages/InitialPage';
import EnumPermissions from './utils/Enums/EnumPermissons';
import TodoPage from './pages/TodoPage';
import DownloadFilesPage from './pages/DownloadFilesPage';
import AccessControlPage from './pages/AccessControlPage';
import UploadedFiles from './pages/UploadedFilesPage';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LoggerProvider>
          <Routes>
            <Route
              path="/"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={InitialPage}
                  permission={EnumPermissions.CAN_ACCESS_HOME}
                />
              }
            />
            <Route
              path="/dashboards"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={DashboardPage}
                  permission={EnumPermissions.CAN_ACCESS_DASHBOARDS}
                />
              }
            />
            <Route
              path="/login"
              element={
                <WrapperRoute
                  isPrivate={false}
                  component={SignIn}
                  permission={EnumPermissions.NO_ROLE}
                />
              }
            />

            <Route
              path="/users"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={Users}
                  permission={EnumPermissions.CAN_ACCESS_USERS}
                />
              }
            />

            <Route
              path="/logs"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={LogsPage}
                  permission={EnumPermissions.CAN_ACCESS_LOGS}
                />
              }
            />

            <Route
              path="*"
              element={
                <WrapperRoute
                  component={SignIn}
                  permission={EnumPermissions.NO_ROLE}
                />
              }
            />

            <Route
              path="/companies"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={CompaniesPage}
                  permission={EnumPermissions.CAN_ACCESS_COMPANIES}
                />
              }
            />

            <Route
              path="/tasks"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={TodoPage}
                  permission={EnumPermissions.CAN_ACCESS_TASK_LIST}
                />
              }
            />

            <Route
              path="/downloads"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={DownloadFilesPage}
                  permission={EnumPermissions.CAN_ACCESS_HOME}
                />
              }
            />

            <Route
              path="/accesscontrol"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={AccessControlPage}
                  permission={EnumPermissions.CAN_ACCESS_ROLES_AND_PERMISSIONS}
                />
              }
            />

            <Route
              path="/usergroups"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={UserGroupsPage}
                  permission={EnumPermissions.CAN_ACCESS_USERGROUPS}
                />
              }
            />

            <Route
              path="/resetPassword/:token"
              element={
                <WrapperRoute
                  isPrivate={false}
                  component={ResetPasswordPage}
                  permission={EnumPermissions.NO_ROLE}
                />
              }
            />

            <Route
              path="/forgotPassword"
              element={
                <WrapperRoute
                  isPrivate={false}
                  component={ForgotPasswordPage}
                  permission={EnumPermissions.NO_ROLE}
                />
              }
            />

            <Route
              path="/uploadedFiles"
              element={
                <WrapperRoute
                  isPrivate={true}
                  component={UploadedFiles}
                  permission={EnumPermissions.CAN_ACCESS_USERGROUPS}
                />
              }
            />
          </Routes>
        </LoggerProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useCallback, useEffect, useState } from 'react';
import { Company, Dashboard } from '../../../pages/CompaniesPage';

interface props {
  company?: Company;
  setOpen: any;
  handleCloseOptions?: any;
  dashboardListForm: Dashboard[];
  handleAdd?: any;
  handleSaveEdit?: any;
  handleClose: any;
}

const CompanyContent: React.FC<props> = ({
  company = undefined,
  setOpen,
  handleCloseOptions = undefined,
  dashboardListForm,
  handleAdd = undefined,
  handleSaveEdit = undefined,
  handleClose,
}) => {
  const [companyNameForm, setCompanyNameForm] = useState<string>('');
  const [companyUrlLogoForm, setCompanyUrlLogoForm] = useState<string>('');
  const [companyMainColorForm, setCompanyMainColorForm] = useState<string>('');
  const [renderValueDashboard, setRenderValueDashboard] = useState<string>('');
  const [required, setRequired] = useState<any>({});
  const [defaultDashboardList, setDefaultDashboardList] = useState<Dashboard[]>(
    [],
  );

  const ItemHeight = 48;
  const ItemPaddingTop = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ItemHeight * 4.5 + ItemPaddingTop,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (company) {
      setCompanyMainColorForm(company.mainColor);
      setCompanyUrlLogoForm(company.urlLogo);
      setCompanyNameForm(company.name);
      setDefaultDashboardList(company.dashboards);

      setRenderValueDashboard(
        company.dashboards.map((obj: any) => {
          return `${obj.dashboardName},`;
        }),
      );
    }
  }, [company, dashboardListForm]);

  const hasRequiredFields = useCallback((formCompany: any): boolean => {
    const requiredObj: any = {};
    if (formCompany.name === '') {
      requiredObj.companyName = true;
    }

    if (formCompany.dashboards.length === 0) {
      requiredObj.dashboards = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);
      const formCompany: any = {
        name: formdata?.get('companyName')?.toString() ?? '',
        dashboards: defaultDashboardList.map((dash: any) => {
          return dash.dashboardId;
        }),
        mainColor: formdata?.get('mainColor')?.toString() ?? '',
        urlLogo: formdata?.get('urlLogo')?.toString() ?? '',
      };

      if (!hasRequiredFields(formCompany)) {
        setOpen(false);
        if (company) {
          handleCloseOptions();
          handleSaveEdit({ ...formCompany, companyId: company.companyId });
        } else {
          handleAdd(formCompany);
          setRenderValueDashboard('');
          setCompanyNameForm('');
        }
      }
    },
    [
      company,
      defaultDashboardList,
      handleAdd,
      handleCloseOptions,
      handleSaveEdit,
      hasRequiredFields,
      setOpen,
    ],
  );

  const handleChange = (event: SelectChangeEvent<any>): void => {
    const {
      target: { value },
    } = event;
    setRenderValueDashboard(
      value.map((obj: any) => {
        return `${obj.dashboardName},`;
      }),
    );
    setDefaultDashboardList(value);
  };

  const handleCancel = (): void => {
    setCompanyNameForm('');
    setDefaultDashboardList([]);
    setRenderValueDashboard('');
    setOpen(false);
    if (company) {
      handleCloseOptions();
    }
    handleClose();
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <DialogTitle>
        {company ? 'Edit company' : 'Add a new company'}
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          margin="dense"
          id="companyName"
          name="companyName"
          value={companyNameForm}
          onChange={event => setCompanyNameForm(event.target.value)}
          label="Company Name"
          type="text"
          variant="outlined"
          error={!!required.companyName}
          helperText={required.companyName ? 'required' : ''}
          style={{ marginBottom: '20px' }}
          inputProps={{ maxLength: 50 }}
        />

        <TextField
          margin="dense"
          id="mainColor"
          name="mainColor"
          value={companyMainColorForm}
          onChange={event => setCompanyMainColorForm(event.target.value)}
          label="Main color"
          type="color"
          variant="outlined"
          style={{ marginBottom: '20px' }}
          inputProps={{ maxLength: 50 }}
        />

        <TextField
          margin="dense"
          id="urLogo"
          name="urlLogo"
          value={companyUrlLogoForm}
          onChange={event => setCompanyUrlLogoForm(event.target.value)}
          label="Url Logo"
          type="text"
          variant="outlined"
          style={{ marginBottom: '20px' }}
          inputProps={{ maxLength: 200 }}
        />

        <FormControl error={!!required.dashboards}>
          <InputLabel id="demo-multiple-checkbox-label">Dashboards</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            name="dashboardList"
            multiple
            value={defaultDashboardList}
            onChange={handleChange}
            renderValue={() => renderValueDashboard}
            input={<OutlinedInput label="Dashboards" />}
            MenuProps={MenuProps}
          >
            {dashboardListForm?.map((dash: any) => (
              <MenuItem key={dash.dashboardId} value={dash}>
                <Checkbox checked={defaultDashboardList.indexOf(dash) > -1} />
                <ListItemText primary={dash.dashboardName} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {required.companyName ? 'required' : ''}
          </FormHelperText>
        </FormControl>

        <img
          src={companyUrlLogoForm}
          alt="Logo company"
          style={{ height: '60px', marginTop: '20px' }}
          hidden={companyUrlLogoForm === ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="submit">{company ? 'Save' : 'Add'}</Button>
      </DialogActions>
    </Box>
  );
};

export default CompanyContent;

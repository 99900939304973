import * as React from 'react';
import { useState, useEffect } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
// import DeleteIcon from '@mui/icons-material/Delete';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { FiCopy } from 'react-icons/fi';
import { Content, Container } from './styles';
import GridMui from '../../components/gridMui';
import api from '../../services/api';
import Loading from '../../components/loading';

// interface UploadedFile {
//   id: number;
//   url: string;
//   originalName: string;
//   usergroups: string[];
// }

const UploadedFilesPage: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [column, setColumn] = useState<any[]>([]);
  const [showNoData, setShowNoData] = useState<boolean>(false);
  useEffect(() => {
    setShowNoData(false);
    api
      .get('/files')
      .then((response: any) => {
        if (response.data) {
          setData(
            response.data.map((x: any) => {
              return {
                id: x.fileId,
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${x.filename}`,
                originalName: x.originalname,
                usergroups: [x.userGroupName],
              };
            }),
          );
          setShowNoData(true);
        }
      })
      .catch((error: any) => {
        console.error(error.response.data.message);
      });
  }, []);

  const handleClickUrlCopy = React.useCallback(
    (id: number) => {
      const test = data.find((x: any) => x.id === id);
      navigator.clipboard.writeText(`${test?.url}`);
    },
    [data],
  );

  const handleDelete = React.useCallback(
    (fileId: any): void => {
      // eslint-disable-next-line no-alert
      const confirmDelete = window.confirm(
        ` Are you sure you want to delete the file ${fileId} ?`,
      );
      if (confirmDelete) {
        api
          .delete(`/files/${fileId}`)
          .then(response => {
            if (response.status === 200) {
              setData([...data.filter(x => x.id !== fileId)]);
            } else {
              console.error(response);
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    [data],
  );

  const RenderValue = (
    props: GridRenderCellParams<string[]>,
  ): React.ReactNode => {
    const { hasFocus, value } = props;

    const buttonElement = React.useRef<HTMLButtonElement | null>(null);
    const rippleRef = React.useRef<TouchRippleActions | null>(null);
    React.useLayoutEffect(() => {
      if (hasFocus) {
        const input = buttonElement.current?.querySelector('input');
        input?.focus();
      } else if (rippleRef.current) {
        rippleRef.current.stop({} as any);
      }
    }, [hasFocus]);

    const tags = value?.map((v: any, index: number) => {
      return (
        <span
          key={index}
          style={{
            border: '1px solid black',
            borderRadius: '3px',
            marginRight: '2px',
            padding: '1px 4px',
          }}
        >
          {v}
        </span>
      );
    });
    return (
      <div
        style={{
          padding: '1px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {tags}
      </div>
    );
  };

  useEffect(() => {
    if (data.length > 0) {
      const columns: GridColDef<(typeof data)[number]>[] = [
        { field: 'id', headerName: 'ID', width: 90 },

        {
          field: 'originalName',
          headerName: 'OriginalName',
          flex: 0.7,
          editable: false,
          width: 180,
        },
        {
          field: 'usergroups',
          headerName: 'Usergroups',
          flex: 1,
          editable: false,
          renderCell: RenderValue,
          width: 180,
        },
        {
          field: 'copyUrl',
          headerName: 'Copy URL',
          align: 'center',
          width: 180,
          renderCell: params => (
            <FiCopy
              style={{ cursor: 'pointer', color: '#b2b2b2' }}
              onClick={(event: any) => {
                event.stopPropagation();
                handleClickUrlCopy(params.row.id);
              }}
            />
          ),
        },

        // {
        //   field: 'delete',
        //   headerName: 'DELETE',
        //   sortable: false,
        //   filterable: false,
        //   renderHeader: () => <span>{'Delete'}</span>,
        //   headerAlign: 'center',
        //   align: 'center',
        //   // headerName: '',
        //   width: 90,
        //   renderCell: params => (
        //     <DeleteIcon
        //       style={{ cursor: 'pointer', color: '#b2b2b2' }}
        //       onClick={(event: any) => {
        //         event.stopPropagation();
        //         handleDelete(params.row.id);
        //       }}
        //     />
        //   ),
        // },
      ];

      setColumn(columns);
    }
  }, [data.length, handleClickUrlCopy, handleDelete]);
  return (
    <Container>
      <h1 style={{ color: 'white' }}>Uploads</h1>
      <Content>
        {data.length > 0 ? (
          <GridMui columns={column} rows={data} pageSizeOptions={20} />
        ) : showNoData ? (
          <span style={{ color: 'white' }}>No file uploaded yet</span>
        ) : (
          <Loading />
        )}
      </Content>
    </Container>
  );
};

export default UploadedFilesPage;

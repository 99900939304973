import * as React from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { GuestUser } from '..';
import { CloseButton, Header } from './styles';

const ListItem = styled('li')(() => ({
  margin: '3px',
}));

interface AlertProps {
  open: boolean;
  message: string;
}

interface props {
  usersList: GuestUser[];
  setUsersList: any;
  usersToDelete: string[];
  setUsersToDelete: any;
  isEdit: boolean;
  setClose?: any;
}

const AddGuestBox: React.FC<props> = ({
  usersList,
  setUsersList,
  usersToDelete,
  setUsersToDelete,
  isEdit,
  setClose = undefined,
}) => {
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [required, setRequired] = useState<any>({});
  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false,
    message: '',
  });

  const handleDeleteChip = (userObj: GuestUser): void => {
    if (isEdit) {
      setUsersToDelete([...usersToDelete, userObj.email]);
    }

    const index = usersList.indexOf(userObj);
    usersList.splice(index, 1);
    setUsersList([...usersList]);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertProps({
      open: false,
      message: '',
    });
  };

  const validateEmail = (email: string): boolean => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return true; // is not valid
    }
    return false; // is valid
  };

  // const onUploadFile = useCallback(
  //   // TODO: fix upload
  //   async (e: any) => {
  //     // let countInvalidEmails = 0;
  //     // const file = e.target.files[0];
  //     // const f = await file.arrayBuffer();
  //     // const wb = read(f);
  //     // const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
  //     // const data = utils.sheet_to_json<User>(ws); // generate objects
  //     // const newUsers = data.map((userObj: User) => {
  //     //   const errors = validateEmail(userObj.email);
  //     //   if (errors) {
  //     //     countInvalidEmails += 1;
  //     //   }
  //     //   return {
  //     //     username: userObj.username,
  //     //     email: userObj.email,
  //     //   };
  //     // });
  //     // if (countInvalidEmails > 0) {
  //     //   setAlertProps({
  //     //     open: true,
  //     //     message:
  //     //       'There are one or more emails with invalid format inside the file',
  //     //   });
  //     // } else {
  //     //   setUsersList(usersList.concat(newUsers));
  //     // }
  //   },
  //   [setUsersList, usersList],
  // );

  const hasRequiredFields = useCallback((userForm: any): boolean => {
    const requiredObj: any = {};
    if (userForm.name === '') {
      requiredObj.name = true;
    }
    if (userForm.lastName === '') {
      requiredObj.lastName = true;
    }

    if (userForm.email === '') {
      requiredObj.email = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleAddUser = useCallback(() => {
    const userForm: GuestUser = {
      name,
      lastName,
      email: userEmail.toLowerCase(),
    };

    if (!hasRequiredFields(userForm)) {
      if (validateEmail(userForm.email)) {
        setAlertProps({
          open: true,
          message: 'invalid format emal',
        });
        return;
      }

      const user = usersList.find((u: GuestUser) => u.email === userForm.email);
      if (!user) {
        setUsersList([...usersList, userForm]);
      } else {
        setAlertProps({
          open: true,
          message: 'This user already exists',
        });
      }

      setName('');
      setLastName('');
      setUserEmail('');
      setRequired({});
    }
  }, [name, lastName, userEmail, hasRequiredFields, usersList, setUsersList]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          alignSelf: 'center',
          height: 'auto',
          paddingBottom: '10px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Header>
            <h3
              style={{
                margin: 'unset',
                marginTop: '12px',
                marginBottom: '5px',
                paddingLeft: '3px',
              }}
            >
              Gäste hinzufügen
            </h3>
            <CloseButton
              title={'Close'}
              variant="contained"
              onClick={() => {
                setClose(false);
              }}
            >
              X
            </CloseButton>
          </Header>

          <TextField
            margin="dense"
            id="userName"
            name="userName"
            value={name}
            onChange={event => setName(event.target.value)}
            label="Name"
            type="text"
            variant="outlined"
            error={!!required.name}
            helperText={required.name ? 'required' : ''}
            style={{ width: '94%', alignSelf: 'center' }}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            margin="dense"
            id="userLastName"
            name="userLastName"
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            label="Last Name"
            type="text"
            variant="outlined"
            error={!!required.lastName}
            helperText={required.lastName ? 'required' : ''}
            style={{ width: '94%', alignSelf: 'center' }}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            margin="dense"
            id="userEmail"
            name="userEmail"
            value={userEmail}
            onChange={event => setUserEmail(event.target.value)}
            label="Email"
            type="text"
            variant="outlined"
            error={!!required.email}
            helperText={required.email ? 'required' : ''}
            style={{ width: '94%', alignSelf: 'center' }}
            inputProps={{ maxLength: 50 }}
          />
          <div
            style={{
              width: '94%',
              alignSelf: 'center',
              paddingTop: '7px',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            {/* <Button variant="contained" component="label"> */}
            {/* <input type="file" hidden onChange={onUploadFile} /> */}
            {/* Import Users */}
            {/* </Button> */}
            <Button onClick={handleAddUser} variant="contained">
              OK
            </Button>
          </div>
        </Box>
        {usersList.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
            }}
          >
            <Paper
              sx={{
                background: 'aliceblue',
                display: 'flex',
                justifyContent: 'start',
                flexWrap: 'wrap',
                listStyle: 'none',
                width: '94%',
                p: 0.5,
                m: 0.5,
              }}
              elevation={4}
            >
              {usersList.map((userObj: GuestUser, index: number) => {
                return (
                  <ListItem key={index}>
                    <Chip
                      label={`${userObj.name} <${userObj.email}>`}
                      size="small"
                      onDelete={() => handleDeleteChip(userObj)}
                    />
                  </ListItem>
                );
              })}
            </Paper>
          </div>
        )}
      </Paper>
      <Snackbar
        open={alertProps.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alertProps.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddGuestBox;

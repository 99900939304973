import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { useCallback, useEffect, useState } from 'react';

import Modal from '../../components/modal';
import api from '../../services/api';
import { Cards, OptionsBar } from './styles';
import CompanyCard from '../../components/cards/companyCard';
import { useLogger } from '../../context/logContext';
import { CustomTextField } from '../AccessControlPage/roleTab/styles';

export interface Company {
  companyId: number;
  name: string;
  dashboards: any;
  urlLogo: string;
  mainColor: string;
  moreInformation: string[];
}

export interface Dashboard {
  dashboardId: number;
  dashboardName: string;
}

const CompaniesPage: React.FC = () => {
  const { logger } = useLogger();
  const [cardsPanel, setCardsPanel] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const handleSubmit = (): void => {
    setOpen(true);
  };

  const handleFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValeuTam = event.target.value.length;
      const filteredCompaniesAux = companies.filter((fil: Company) => {
        const nFirstCharacters = fil.name.slice(0, inputValeuTam);
        return (
          nFirstCharacters.toLowerCase() === event.target.value.toLowerCase()
        );
      });
      setFilteredCompanies(filteredCompaniesAux);
    },
    [companies],
  );

  useEffect(() => {
    api
      .get('/superset/getAllDashboards')
      .then((response: any) => {
        setDashboards(
          response.data.map((dash: any) => {
            return {
              dashboardName: dash.dashboard_title,
              dashboardId: dash.id,
            };
          }),
        );
      })
      .catch((error: any) => {
        console.error(error.response.data.message);
      });
  }, []);

  const getCompanies = useCallback(() => {
    api
      .get('/companies')
      .then((response: any) => {
        setCompanies(
          response.data.map((company: Company) => {
            return {
              ...company,
              dashboards: dashboards.filter((dash: Dashboard) =>
                company.dashboards.includes(dash.dashboardId),
              ),
            };
          }),
        );
        setFilteredCompanies(
          response.data.map((company: Company) => {
            return {
              ...company,
              dashboards: dashboards.filter((dash: Dashboard) =>
                company.dashboards.includes(dash.dashboardId),
              ),
            };
          }),
        );
      })
      .catch((error: any) => {
        console.error(error.response.data.message);
      });
  }, [dashboards]);

  const handleAdd = useCallback(
    (newCompany: Company) => {
      api
        .post('/companies', newCompany)
        .then(() => {
          getCompanies();
        })
        .catch(error => {
          console.error(error.response.data.message);
          logger(
            'error',
            'create new company',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getCompanies, logger],
  );

  const handleSaveEdit = useCallback(
    (companyToEdit: any) => {
      api
        .put(`/companies/${companyToEdit.companyId}`, companyToEdit)
        .then(() => {
          getCompanies();
        })
        .catch(error => {
          console.error(error.response.data.message);
          logger('error', 'Edit company', `${error.code} - ${error.message}`);
        });
    },
    [getCompanies, logger],
  );

  const handleDelete = useCallback(
    (obj: any) => {
      api
        .delete(`/companies/${obj.company.companyId}`)
        .then(() => {
          getCompanies();
        })
        .catch(error => {
          console.error(error.response.data.message);
          logger('error', 'Delete company', `${error.code} - ${error.message}`);
        });

      obj.handleCloseOptions();

      const index = companies.indexOf(obj.company);
      companies.splice(index, 1);
      setCompanies([...companies]);
    },
    [companies, getCompanies, logger],
  );

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    setCardsPanel(
      filteredCompanies.map((company: Company, index: number) => {
        return (
          <div key={index} style={{ marginTop: '25px' }}>
            <CompanyCard
              key={index}
              company={company}
              handleDelete={handleDelete}
              handleSaveEdit={handleSaveEdit}
              dashboardListForm={dashboards}
            ></CompanyCard>
          </div>
        );
      }),
    );
  }, [dashboards, filteredCompanies, handleDelete, handleSaveEdit]);

  return (
    <>
      <OptionsBar id="options">
        <div id="title">
          <h1 style={{ color: 'white' }}>Companies</h1>
        </div>

        <div
          id="searchBar"
          style={{
            width: '50%',
            alignSelf: 'center',
            paddingTop: '10px',
          }}
        >
          <CustomTextField
            id="outlined-basic"
            label="Search..."
            onChange={handleFilter}
            variant="outlined"
            style={{ width: '80%' }}
          />
        </div>

        <div id="buttonAdd" style={{ alignSelf: 'center', paddingTop: '10px' }}>
          <Fab color="primary" aria-label="add" onClick={handleSubmit}>
            <AddIcon />
          </Fab>
        </div>
      </OptionsBar>
      <Cards id="cards">{cardsPanel}</Cards>
      <Modal
        content={'company'}
        companyProp={{
          open,
          setOpen,
          dashboardListForm: dashboards,
          handleAdd,
        }}
      ></Modal>
    </>
  );
};

export default CompaniesPage;

import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { useCallback, useEffect, useState } from 'react';
import { Permission, Role } from '../../../pages/AccessControlPage/roleTab';

interface props {
  role?: Role;
  setOpen: any;
  handleCloseOptions?: any;
  permissions: Permission[];
  handleAdd?: any;
  handleSaveEdit?: any;
  handleClose: any;
}

const RoleContent: React.FC<props> = ({
  role = undefined,
  setOpen,
  handleCloseOptions = undefined,
  permissions,
  handleAdd = undefined,
  handleSaveEdit = undefined,
  handleClose,
}) => {
  const [roleNameForm, setRoleNameForm] = useState<string>('');
  const [roleDescriptionForm, setRoleDescriptionForm] = useState<string>('');
  const [renderValuePermission, setRenderValuePermission] =
    useState<string>('');
  const [required, setRequired] = useState<any>({});
  const [defaultPermissionsList, setDefaultPermissionsList] = useState<
    Permission[]
  >([]);

  const ItemHeight = 48;
  const ItemPaddingTop = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ItemHeight * 4.5 + ItemPaddingTop,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (role) {
      setRoleNameForm(role.name);
      setRoleDescriptionForm(role.description);
      setDefaultPermissionsList(role.permissions.map((r: any) => r.name));

      setRenderValuePermission(
        role.permissions
          .map((obj: any) => {
            return `${obj.name.split('_')[2]}`;
          })
          .join(', '),
      );
    }
  }, [role]);

  const hasRequiredFields = useCallback((formRole: any): boolean => {
    const requiredObj: any = {};
    if (formRole.name === '') {
      requiredObj.roleName = true;
    }

    if (formRole.permissions.length === 0) {
      requiredObj.permissions = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);

      const selectedPermissions: any = [];
      defaultPermissionsList.forEach((permission: any) => {
        const permissionObj = permissions.find(
          (p: any) => p.name === permission,
        );
        if (permissionObj) {
          selectedPermissions.push(permissionObj);
        }
      });

      const formRole: any = {
        name: formdata?.get('roleName')?.toString() ?? '',
        description: formdata?.get('description')?.toString() ?? '',
        permissions: selectedPermissions,
      };

      if (!hasRequiredFields(formRole)) {
        setOpen(false);
        if (role) {
          handleCloseOptions();
          handleSaveEdit({ ...formRole, roleId: role.roleId });
        } else {
          handleAdd(formRole);
          setRenderValuePermission('');
          setRoleNameForm('');
        }
      }
    },
    [
      defaultPermissionsList,
      handleAdd,
      handleCloseOptions,
      handleSaveEdit,
      hasRequiredFields,
      permissions,
      role,
      setOpen,
    ],
  );

  const handleChange = useCallback((event: any): void => {
    const {
      target: { value },
    } = event;

    setRenderValuePermission(
      value
        .map((obj: any) => {
          return `${obj.split('_')[2]}`;
        })
        .join(', '),
    );
    setDefaultPermissionsList(value);
  }, []);

  const handleCancel = (): void => {
    setRoleNameForm('');
    setDefaultPermissionsList([]);
    setRenderValuePermission('');
    setOpen(false);
    if (role) {
      handleCloseOptions();
    }
    handleClose();
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <DialogTitle>{role ? 'Edit role' : 'Add a new role'}</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          margin="dense"
          id="roleName"
          name="roleName"
          value={roleNameForm}
          onChange={event => setRoleNameForm(event.target.value)}
          label="Role Name"
          type="text"
          variant="outlined"
          error={!!required.roleName}
          helperText={required.roleName ? 'required' : ''}
          style={{ marginBottom: '20px' }}
          inputProps={{ maxLength: 50 }}
        />

        <TextField
          margin="dense"
          id="description"
          name="description"
          value={roleDescriptionForm}
          onChange={event => setRoleDescriptionForm(event.target.value)}
          label="Description"
          type="text"
          variant="outlined"
          style={{ marginBottom: '20px' }}
          inputProps={{ maxLength: 50 }}
        />

        <FormControl error={!!required.permissions}>
          <InputLabel id="demo-multiple-checkbox-label">Permissions</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            name="permissions"
            multiple
            onChange={handleChange}
            value={defaultPermissionsList}
            renderValue={() => renderValuePermission}
            input={<OutlinedInput label="Permissions" />}
            MenuProps={MenuProps}
          >
            {permissions?.map((permission: any) => (
              <MenuItem key={permission.permissionId} value={permission.name}>
                <Checkbox
                  checked={defaultPermissionsList.some(
                    (p: any) => p === permission.name,
                  )}
                />
                <ListItemText primary={permission.name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{required.roleName ? 'required' : ''}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="submit">{role ? 'Save' : 'Create'}</Button>
      </DialogActions>
    </Box>
  );
};

export default RoleContent;

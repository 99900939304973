import * as React from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Backdrop, Button, CircularProgress, Tooltip } from '@mui/material';
import { FiFile } from 'react-icons/fi';
import api from '../../services/api';
import { SelectFileDiv } from './styles';

const ListItem = styled('li')(() => ({
  margin: '3px',
}));

interface AlertProps {
  open: boolean;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
}

interface props {
  downloadItemsList: DownloadItem[];
  setDownloadItemList: any;
  downloadItemsUrlToDelete: string[];
  setDownloadItemsUrlToDelete: any;
  isEdit: boolean;
  usergroupId: number;
}

export interface DownloadItem {
  description: string;
  url: string;
  fileId: number;
  usergroupId?: number;
  fileToBeUploaded?: File;
}

export const AddUrlDownloads: React.FC<props> = ({
  downloadItemsList,
  setDownloadItemList,
  downloadItemsUrlToDelete,
  setDownloadItemsUrlToDelete,
  isEdit,
  usergroupId,
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [required, setRequired] = useState<any>({});
  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleDeleteChip = (downloadItem: DownloadItem): void => {
    if (isEdit) {
      setDownloadItemsUrlToDelete([
        ...downloadItemsUrlToDelete,
        downloadItem.url,
      ]);
    }

    const index = downloadItemsList.indexOf(downloadItem);
    downloadItemsList.splice(index, 1);
    setDownloadItemList([...downloadItemsList]);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertProps({
      open: false,
      message: '',
      severity: 'info',
    });
  };

  const handleFileUpload = useCallback(() => {
    if (description === '') {
      const requiredObj: any = {};
      requiredObj.description = true;
      setRequired(requiredObj);
      setAlertProps({
        open: true,
        message: 'Description is required',
        severity: 'error',
      });
      return;
    }
    setRequired({});

    const newImplementation = true;

    if (selectedFile) {
      if (newImplementation) {
        // create new DownloadItem without id.
        // upload will be done on "save all"
        const newDownloadItem: DownloadItem = {
          fileId: -1,
          description,
          url: '',
          fileToBeUploaded: selectedFile,
        };

        // console.log('File should be uploaded', newDownloadItem);

        setDownloadItemList([...downloadItemsList, newDownloadItem]);
        setSelectedFile(undefined);
        setDescription('');

        // clear the file upload input component
        document.getElementById('fileSelectInput')?.setAttribute('value', '');
        return;
      }

      const data = new FormData();

      data.append('file', selectedFile);
      data.append('description', description);
      setIsUploading(true);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      api
        .post(`/files/${usergroupId}`, data, config)
        .then(response => {
          const urlString = response.request?.responseURL.replace(
            '/files',
            '/uploads',
          );

          const lastSlashIndex = urlString.lastIndexOf('/');
          const newUrlString = urlString.substring(0, lastSlashIndex);

          const newDownloadItem: DownloadItem = {
            fileId: response.data.fileId,
            description,
            url: `${newUrlString}/${response.data.filename ?? ''}`,
          };

          setDownloadItemList([...downloadItemsList, newDownloadItem]);

          setSelectedFile(undefined);
          setDescription('');

          setAlertProps({
            open: true,
            message: 'File uploaded successfully',
            severity: 'success',
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  }, [
    description,
    downloadItemsList,
    selectedFile,
    setDownloadItemList,
    usergroupId,
  ]);
  const handleFileSelectionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = e.target.files ? e.target.files[0] : undefined;
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

    if (file) {
      if (file.size > maxSizeInBytes) {
        setAlertProps({
          open: true,
          message: 'The file exceeds the maximum allowed size of 5MB.',
          severity: 'error',
        });
        e.target.value = ''; // Clear the input
        return;
      }

      setSelectedFile(file);
    }
  };

  const shouldEnableUploadButton = selectedFile && description;

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          alignSelf: 'center',
          height: 'auto',
          paddingBottom: '10px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ width: '94%', alignSelf: 'center' }}>
            <h3
              style={{
                margin: 'unset',
                marginTop: '12px',
                marginBottom: '5px',
                paddingLeft: '3px',
              }}
            >
              Upload files
            </h3>
          </div>

          <div
            style={{
              display: 'flex',
              marginLeft: '13px',
              flexWrap: 'wrap',
            }}
          >
            <SelectFileDiv>
              <input
                id="fileSelectInput"
                type="file"
                // accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.zip,.rar,.7z,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.svg,.mp4,.avi,.mkv,.mov,.wmv,.flv,.mp3,.wav,.wma,.ogg,.aac,.flac,.zip,.rar,.7z"
                accept=".pdf"
                onChange={handleFileSelectionChange}
              />
            </SelectFileDiv>
            <TextField
              margin="dense"
              id="description"
              name="description"
              value={description}
              onChange={event => setDescription(event.target.value)}
              label="Description"
              type="text"
              variant="outlined"
              error={!!required.description}
              helperText={required.description ? 'required' : ''}
              inputProps={{ maxLength: 100 }}
              style={{ alignSelf: 'center', flexGrow: '1' }}
              onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
            />
            <Button
              style={{
                flexGrow: '1',
                height: '100%',
                minHeight: '55px',
                margin: '8px 13px 0 13px',
              }}
              variant={shouldEnableUploadButton ? 'contained' : 'outlined'}
              onClick={() => handleFileUpload()}
            >
              <FiFile /> Upload file
            </Button>
          </div>
        </Box>
        {downloadItemsList.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
            }}
          >
            <Paper
              sx={{
                background: 'aliceblue',
                display: 'flex',
                justifyContent: 'start',
                flexWrap: 'wrap',
                listStyle: 'none',
                width: '94%',
                p: 0.5,
                m: 0.5,
              }}
              elevation={4}
            >
              {downloadItemsList.map(
                (downloadItem: DownloadItem, index: number) => {
                  return (
                    <ListItem key={index}>
                      <Tooltip
                        arrow
                        title={`${downloadItem.url}`}
                        placement="top-start"
                      >
                        <Chip
                          label={`${downloadItem.description}`}
                          size="small"
                          onDelete={() => handleDeleteChip(downloadItem)}
                        />
                      </Tooltip>
                    </ListItem>
                  );
                },
              )}
            </Paper>
          </div>
        )}
      </Paper>
      <Snackbar
        open={alertProps.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertProps.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alertProps.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isUploading}
      >
        <CircularProgress color="inherit" />
        <span style={{ marginLeft: '12px' }}>Uploading</span>
      </Backdrop>
    </>
  );
};

import * as React from 'react';

import { useEffect, useState } from 'react';
import EisenhowerMatrix from '../../../components/eisenhowerMatrix';
import { TabPanel } from './styles';
import { Task } from '..';

interface Props {
  chosenTab: number;
  taskList: Task[];
  setTaskList: Function;
}

const EisenhowerTab: React.FC<Props> = ({
  chosenTab,
  taskList,
  setTaskList,
}) => {
  const [tasksPerQuadrant, setTasksPerQuadrant] = useState<any>([]);

  const getEisenhowerData = (tasks: Task[]): any => {
    const enumValues: any = {
      'Sehr Gering': 'vLow',
      Gering: 'low',
      Hoch: 'high',
      'Sehr Hoch': 'vHigh',
    };

    const enumQuadrants: any = {
      'e(vLow)i(vLow)': 'delegate',
      'e(vLow)i(low)': 'delegate',
      'e(low)i(vLow)': 'delegate',
      'e(low)i(low)': 'delegate',

      'e(vLow)i(high)': 'doit',
      'e(vLow)i(vHigh)': 'doit',
      'e(low)i(high)': 'doit',
      'e(low)i(vHigh)': 'doit',

      'e(high)i(vLow)': 'delete',
      'e(high)i(low)': 'delete',
      'e(vHigh)i(vLow)': 'delete',
      'e(vHigh)i(low)': 'delete',

      'e(high)i(high)': 'schedule',
      'e(high)i(vHigh)': 'schedule',
      'e(vHigh)i(high)': 'schedule',
      'e(vHigh)i(vHigh)': 'schedule',
    };

    const quadrants: any = {
      delegate: {
        'e(vLow)i(vLow)': [],
        'e(vLow)i(low)': [],
        'e(low)i(vLow)': [],
        'e(low)i(low)': [],
      },
      doit: {
        'e(vLow)i(high)': [],
        'e(vLow)i(vHigh)': [],
        'e(low)i(high)': [],
        'e(low)i(vHigh)': [],
      },
      delete: {
        'e(high)i(vLow)': [],
        'e(high)i(low)': [],
        'e(vHigh)i(vLow)': [],
        'e(vHigh)i(low)': [],
      },
      schedule: {
        'e(high)i(high)': [],
        'e(high)i(vHigh)': [],
        'e(vHigh)i(high)': [],
        'e(vHigh)i(vHigh)': [],
      },
    };

    tasks?.forEach((task: any) => {
      const effort = enumValues[task.effort];
      const impact = enumValues[task.impact];
      const key = `e(${effort})i(${impact})`;
      const quad = enumQuadrants[key];

      quadrants[quad][key].push(task);
    });

    return quadrants;
  };

  useEffect(() => {
    const agroupedTasksByQuadrant = getEisenhowerData(taskList);
    setTasksPerQuadrant(agroupedTasksByQuadrant);
  }, [taskList]);

  return (
    <>
      {chosenTab === 1 && (
        <TabPanel hidden={chosenTab !== 1}>
          <EisenhowerMatrix
            taskList={taskList}
            setTaskList={setTaskList}
            tasksPerQuadrant={tasksPerQuadrant}
          />
        </TabPanel>
      )}
    </>
  );
};

export default EisenhowerTab;

import React, { useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import api from '../../services/api';
import { Container, Content } from './styles';
import GridMui from '../../components/gridMui';
import Loading from '../../components/loading';

const LogsPage: React.FC = () => {
  const [logs, setLogs] = useState<any>([]);
  const [column, setColumn] = useState<any[]>([]);

  const renderCell = (params: GridRenderCellParams): React.ReactNode => {
    let color = '';
    if (params.row.type === 'success') {
      color = '#DCF0DB';
    }

    if (params.row.type === 'warning') {
      color = '#FDFDDE';
    }

    if (params.row.type === 'error') {
      color = '#FDDEDE';
    }

    return (
      <Box
        sx={{
          backgroundColor: color,
          width: '100%',
          height: '100%',
        }}
      >
        {params.value}
      </Box>
    );
  };

  useEffect(() => {
    api
      .get('/logging')
      .then((response: any) => {
        setLogs(response.data);
      })
      .catch((error: any) => {
        console.error(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (logs.length > 0) {
      const columns: GridColDef<(typeof logs)[number]>[] = [
        {
          field: 'user',
          headerName: 'User',
          flex: 1,
          editable: false,
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          flex: 1,
          editable: false,
        },

        {
          field: 'action',
          headerName: 'Action',
          flex: 1,
          editable: false,
        },

        {
          field: 'type',
          headerName: 'Severity',
          flex: 1,
          editable: false,
          renderCell,
        },

        {
          field: 'message',
          headerName: 'Message',
          flex: 1,
          editable: false,
        },
      ];

      setColumn(columns);
    }
  }, [logs.length]);

  return (
    <Container>
      <h1 style={{ color: 'white' }}>Logs</h1>
      <Content>
        {logs.length > 0 ? (
          <GridMui columns={column} rows={logs} />
        ) : (
          <Loading />
        )}
      </Content>
    </Container>
  );
};

export default LogsPage;

import React, { useEffect, useState } from 'react';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
import Quadrant from '..';

interface Props {
  data: any;
  setTask: any;
}

const ScheduleQuadrant: React.FC<Props> = ({ data, setTask }) => {
  const [scheduleInfo, setScheduleInfo] = useState<any>({});

  useEffect(() => {
    setScheduleInfo({
      subQuadData: {
        topLeft: data ? data['e(high)i(high)'] : [],
        topRight: data ? data['e(high)i(vHigh)'] : [],
        bottomLeft: data ? data['e(vHigh)i(high)'] : [],
        bottomRight: data ? data['e(vHigh)i(vHigh)'] : [],
      },
      name: 'PLANEN',
      description:
        'Diese Aufgaben sind wichtig und haben erhebliche Auswirkungen, können aber mehr Aufwand oder Ressourcen erfordern. Sie müssen sorgfältig abgewogen und entsprechend geplant werden.',
      backQuadrantsColor: 'rgba(150, 200, 254, 0.85)',
      icon: (
        <EventRepeatRoundedIcon
          sx={{
            width: '11%',
            height: '40%',
            marginTop: '10%',
            marginRight: '3%',
          }}
        />
      ),
      setTask,
    });
  }, [data, setTask]);

  return <Quadrant info={scheduleInfo} id="schedule-quadrant"></Quadrant>;
};

export default ScheduleQuadrant;

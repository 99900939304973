import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Button } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TreeItemContainer } from './styles';

interface Props {
  onAddItem: Function;
  onEditItem: Function;
  data: any;
  setData: Function;
}

const EBTreeView: React.FC<Props> = ({
  onAddItem,
  data,
  setData,
  onEditItem,
}) => {
  const insertItem = useCallback(
    (parent: any, draggedItem: any, targetItem: any) => {
      const newItems = parent.items.map((node: any) => {
        const newNode = { ...node };

        if (node.widgetName === targetItem.widgetName) {
          if (node.items) {
            newNode.items.push(draggedItem);
          } else {
            newNode.items = [draggedItem];
          }
        } else if (node.items) {
          return insertItem(node, draggedItem, targetItem);
        }

        return newNode;
      });

      if (parent.widgetName === targetItem.widgetName) {
        newItems.push(draggedItem);
      }

      return { ...parent, items: newItems };
    },
    [],
  );

  const removeItem = useCallback((parent: any, itemToRemove: any) => {
    const newItems = parent.items.map((node: any) => {
      if (node.items) {
        return removeItem(node, itemToRemove);
      }

      return node;
    });

    const filteredItems = newItems.filter(
      (item: any) => item.widgetName !== itemToRemove.widgetName,
    );

    return { ...parent, items: filteredItems };
  }, []);

  const renderLabel = useCallback(
    (node: any): any => (
      <TreeItemContainer>
        <span>{node.widgetName}</span>
        <div>
          {node.widgetName !== 'widgets list' && (
            <Button
              variant="text"
              size="small"
              style={{ minWidth: 'unset' }}
              onClick={e => {
                e.stopPropagation();
                onEditItem(node, false);
              }}
            >
              <ModeEditIcon style={{ fontSize: '0.8vw' }} />
            </Button>
          )}

          <Button
            variant="text"
            size="small"
            style={{ minWidth: 'unset' }}
            onClick={e => {
              e.stopPropagation();
              onAddItem(node, true);
            }}
          >
            <AddCircleIcon style={{ fontSize: '0.8vw' }} />
          </Button>
        </div>
      </TreeItemContainer>
    ),
    [onAddItem, onEditItem],
  );

  const ItemTree = useCallback(
    (props: any): any => {
      const { children, node, ...other } = props;

      return (
        <TreeItem
          draggable
          onDragStart={(event: any) => {
            event.dataTransfer.setData(
              'application/json',
              JSON.stringify(node),
            );
            event.stopPropagation();
          }}
          onDrop={(event: any) => {
            const draggedItem = JSON.parse(
              event.dataTransfer.getData('application/json'),
            );

            if (draggedItem.widgetName !== node.widgetName) {
              const objData = { widgetName: 'widgets list', items: data };

              // the removeItem() must to be first then insertItem()
              const dataWithoutItem = removeItem(objData, draggedItem);
              const dataWithNewItem = insertItem(
                dataWithoutItem,
                draggedItem,
                node,
              );

              setData(dataWithNewItem.items);
            }

            event.stopPropagation();
          }}
          onDragOver={(event: any) => {
            event.preventDefault();
          }}
          label={renderLabel(node)}
          {...other}
        >
          {children}
        </TreeItem>
      );
    },
    [data, insertItem, removeItem, renderLabel, setData],
  );

  const buildTree = useCallback(
    (obj: any) => {
      return (
        <ItemTree node={obj} key={obj.widgetName} itemId={obj.widgetName}>
          {obj.items?.map((node: any) => {
            return buildTree(node);
          })}
        </ItemTree>
      );
    },
    [ItemTree],
  );

  return (
    <Box id="box" sx={{ minWidth: '14vw' }}>
      <SimpleTreeView
        id="simpleTree"
        style={{ display: 'flex', margin: '1.3vw' }}
        defaultExpandedItems={['widgets list']}
      >
        {buildTree({ widgetName: 'widgets list', items: data })}
      </SimpleTreeView>
    </Box>
  );
};

export default EBTreeView;

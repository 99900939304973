import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

export interface User {
  id: string;
  name: string;
  email: string;
  urlLogo: string;
  mainColor: string;
  companyId: number;
  userGroupName: string;
  isGuest: boolean;
  show_task_tutorial: boolean;
  permissions: any[];
  usergroups: any[];
  selectedUsergroup: any;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  getUserGroups(user: User): Promise<User>;
  updateSelectedUsergroup(usergroup: any): void;
}

interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Victor-insights:token');
    const user = localStorage.getItem('@Victor-insights:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const updateSelectedUsergroup = useCallback(
    (usergroup: any) => {
      const newUser: any = {
        ...data.user,
        selectedUsergroup: usergroup,
      };

      setData(prev => ({
        ...prev,
        user: newUser,
      }));
    },
    [data.user],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@Victor-insights:token');
    localStorage.removeItem('@Victor-insights:user');

    setData({} as AuthState);
  }, []);

  const getUserGroups = useCallback(
    async (user: User) => {
      let selectedUsergroup1: any = user.selectedUsergroup
        ? user.selectedUsergroup
        : undefined;
      let usergroups: any;
      let permissionsWithoutDuplicates: any;

      const userWithData: User = user;

      let isGuest = false;
      await api
        .get(`usergroups/email/${user.email}`)
        .then(response => {
          if (response.data.isGuest === true) {
            isGuest = true;
          } else {
            usergroups = response.data;
            let allPermissions: any[] = [];
            response.data?.forEach((userGroup: any) => {
              allPermissions = allPermissions.concat(
                userGroup.roles[0].permissions,
              );
            });
            permissionsWithoutDuplicates = allPermissions.reduce(
              (acc: any, permission: any) => {
                // Verifica se já existe um objeto com o mesmo 'id' no acumulador
                if (
                  !acc.some(
                    (item: any) =>
                      item.permissionId === permission.permissionId,
                  )
                ) {
                  acc.push(permission);
                }
                return acc;
              },
              [],
            );
            if (response.data.length === 1) {
              [selectedUsergroup1] = response.data;
            }
          }
        })
        .catch(err => {
          console.log(err);
          signOut();
        });

      userWithData.permissions = permissionsWithoutDuplicates;
      userWithData.usergroups = usergroups;
      userWithData.selectedUsergroup = selectedUsergroup1;
      userWithData.isGuest = isGuest;

      return userWithData;
    },
    [signOut],
  );

  const signIn = useCallback(
    async ({ email, password }: any) => {
      try {
        const response = await api.post('sessions', {
          email,
          password,
        });

        const { token, user } = response.data;

        if (token === 'WP-CODE') {
          return { weakPassword: true };
        }

        if (token && user) {
          localStorage.setItem('@Victor-insights:token', token);
          localStorage.setItem('@Victor-insights:user', JSON.stringify(user));

          api.defaults.headers.authorization = `Bearer ${token}`;
        }

        const userWithData: User = await getUserGroups(user);
        setData({ token, user: userWithData });

        if (userWithData.isGuest) {
          return { signIn: true, isGuest: true };
        }

        return { signIn: true, isGuest: false };
      } catch (error: any) {
        console.error(error);
        return { signIn: false, isGuest: false };
      }
    },
    [getUserGroups],
  );

  useEffect(() => {
    api.interceptors.response.use(
      (response: any) => response,
      (err: any) => {
        if (err.response.status === 401) {
          // console.error('Unauthorized');
          signOut();
        }

        return Promise.reject(err);
      },
    );
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        getUserGroups,
        updateSelectedUsergroup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };

import React, { useEffect, useState } from 'react';

import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import Quadrant from '..';

interface Props {
  data: any;
  setTask: any;
}

const AssingToQuadrant: React.FC<Props> = ({ data, setTask }) => {
  const [assignToInfo, setAssignToInfo] = useState<any>({});

  useEffect(() => {
    setAssignToInfo({
      subQuadData: {
        topLeft: data ? data['e(vLow)i(vLow)'] : [],
        topRight: data ? data['e(vLow)i(low)'] : [],
        bottomLeft: data ? data['e(low)i(vLow)'] : [],
        bottomRight: data ? data['e(low)i(low)'] : [],
      },
      name: 'ZUWEISEN AN',
      description:
        'Diese Aufgaben haben eine niedrigere Priorität, da sie nur minimale Auswirkungen haben und wenig Aufwand erfordern. Sie können in Angriff genommen werden, wenn Zeit und Ressourcen es erlauben.',
      backQuadrantsColor: 'rgba(250, 238, 189, 0.80)',
      icon: (
        <Groups2RoundedIcon
          sx={{
            width: '11%',
            height: '40%',
            marginTop: '10%',
            marginRight: '3%',
          }}
        />
      ),
      setTask,
    });
  }, [data, setTask]);

  return <Quadrant info={assignToInfo} id="assing-to-quadrant"></Quadrant>;
};

export default AssingToQuadrant;
